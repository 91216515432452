// Daily Traffic Dashboards Default

export const barChartDataDailyTraffic = [
  {
    name: "Daily Traffic",
    data: [20, 30, 40, 20, 45, 50, 30],
  },
];


export const barChartHistoricResult =  [{
  name: 'Net Profit',
  data: [44.52, 55, 57]
}, {
  name: 'Revenue',
  data: [76, 85, 101]
}, {
  name: 'Free Cash Flow',
  data: [35, 41, 36]
}];
export const barChartHistoricResultOptions = function (axis_categories, text_color)  {
  return {
    colors:['#da991f', '#027ddf', '#01c589'],
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: true,
      labels: {
        colors: text_color,
        fontSize: "14px",
        fontWeight: "500",
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      },
    },
    dataLabels: {
      enabled: true,
      style:{
        fontSize: "14px",
        fontWeight: "400",
        colors: [text_color]
      }
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: axis_categories,
      show: false,
      labels: {
        show: true,
        style: {
          colors: text_color,
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },

    },
    yaxis: {
      title: {
        text: '$ %'
      },
      show: false,
      color: text_color,
      labels: {
        show: true,
        style: {
          colors: text_color,
          fontWeight: "500",
          fontSize: "14px",
        },
      },
    },
    grid: {
      show: false,
      strokeDashArray: 5,
      yaxis: {
        lines: {
          show: true,
        },
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return  val + "%"
        }
      },
      style: {
        fontSize: "12px",
        fontFamily: undefined,
      },
      onDatasetHover: {
        style: {
          fontSize: "12px",
          fontFamily: undefined,
        },
      },
      theme: "dark",

    }
  }
}

export const barChartOptionsDailyTraffic = {

  chart: {
    toolbar: {
      show: false,
    },
  },
  tooltip: {
    style: {
      fontSize: "12px",
      fontFamily: undefined,
    },
    onDatasetHover: {
      style: {
        fontSize: "12px",
        fontFamily: undefined,
      },
    },
    theme: "dark",
  },
  xaxis: {
    categories: ["00", "04", "08", "12", "14", "16", "18"],
    show: false,
    labels: {
      show: true,
      style: {
        colors: "#A3AED0",
        fontSize: "14px",
        fontWeight: "500",
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: false,
    color: "black",
    labels: {
      show: true,
      style: {
        colors: "#CBD5E0",
        fontSize: "14px",
      },
    },
  },
  grid: {
    show: false,
    strokeDashArray: 5,
    yaxis: {
      lines: {
        show: true,
      },
    },
    xaxis: {
      lines: {
        show: false,
      },
    },
  },
  fill: {
    type: "gradient",
    gradient: {
      type: "vertical",
      shadeIntensity: 1,
      opacityFrom: 0.7,
      opacityTo: 0.9,
      colorStops: [
        [
          {
            offset: 0,
            color: "#4318FF",
            opacity: 1,
          },
          {
            offset: 100,
            color: "rgba(67, 24, 255, 1)",
            opacity: 0.28,
          },
        ],
      ],
    },
  },
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      borderRadius: 10,
      columnWidth: "40px",
    },
  },
};

// Consumption Users Reports

export const barChartDataConsumption = [
  {
    name: "PRODUCT A",
    data: [400, 370, 330, 390, 320, 350, 360, 320, 380],
  },
  {
    name: "PRODUCT B",
    data: [400, 370, 330, 390, 320, 350, 360, 320, 380],
  },
  {
    name: "PRODUCT C",
    data: [400, 370, 330, 390, 320, 350, 360, 320, 380],
  },
];

export const barChartOptionsConsumption = {
  chart: {
    stacked: true,
    toolbar: {
      show: false,
    },
  },
  tooltip: {
    style: {
      fontSize: "12px",
      fontFamily: undefined,
    },
    onDatasetHover: {
      style: {
        fontSize: "12px",
        fontFamily: undefined,
      },
    },
    theme: "dark",
  },
  xaxis: {
    categories: ["17", "18", "19", "20", "21", "22", "23", "24", "25"],
    show: false,
    labels: {
      show: true,
      style: {
        colors: "#A3AED0",
        fontSize: "14px",
        fontWeight: "500",
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: false,
    color: "black",
    labels: {
      show: false,
      style: {
        colors: "#A3AED0",
        fontSize: "14px",
        fontWeight: "500",
      },
    },
  },

  grid: {
    borderColor: "rgba(163, 174, 208, 0.3)",
    show: true,
    yaxis: {
      lines: {
        show: false,
        opacity: 0.5,
      },
    },
    row: {
      opacity: 0.5,
    },
    xaxis: {
      lines: {
        show: false,
      },
    },
  },
  fill: {
    type: "solid",
    colors: ["#5E37FF", "#6AD2FF", "#E1E9F8"],
  },
  legend: {
    show: false,
  },
  colors: ["#5E37FF", "#6AD2FF", "#E1E9F8"],
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      borderRadius: 10,
      columnWidth: "20px",
    },
  },
};

export const donutOptions = function (label, text_color){
  return {
    labels: label,
    colors: ['#da991f', '#027ddf', "#38B2AC"],
    chart: {
      type: 'donut',
      width: "90px",
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    stroke: {
      show: false
    },
    legend: {
      show: true,
      labels: {
        colors: text_color,
        fontSize: "14px",
        fontWeight: "500",
      }
    },
    dataLabels: {
      enabled: true,
      dropShadow: {
        enabled: false,
      },
      style:{
        fontSize: "14px",
        fontWeight: "400",
        colors: [text_color]
      }
    },
    hover: { mode: null },
    plotOptions: {
      donut: {
        expandOnClick: false,
        donut: {
          labels: {
            show: true,
          },
        },
      },
      pie: {
        expandOnClick: false,
        donut: {
          labels: {
            show: true,
            name:{
              fontSize: "14px",
              fontWeight: "400",
              color: text_color,
              show: true
            },
            value: {
              fontSize: "14px",
              fontWeight: "400",
              color: text_color,
              show: true
            },
            total: {
              fontSize: "14px",
              fontWeight: "400",
              color: text_color,
              show: true
            }
          }
        }
      }
    },
    fill: {
      colors: ['#da991f', '#027ddf', "#38B2AC"],
    },
    tooltip: {
      enabled: true,
      theme: "dark",
    },
  }
}

export const progressDonutOptions = function (text_color){
  return {
    colors: ['#f44336', '#4CAF50'],
    chart: {
      type: 'donut',
      width: "90px",
    },
    dataLabels: {
      enabled: false
    },
    states: {
      hover: {
        "show": false,
        filter: {
          type: "none",
        },
      },
    },
    stroke: {
      show: false
    },
    legend: {
      show: false,
      labels: {
        colors: text_color,
        fontSize: "14px",
        fontWeight: "500",
      }
    },
    hover: { mode: null },
    plotOptions: {
      donut: {
        expandOnClick: false,
        donut: {
          labels: {
            show: false,
          },
        },
      },
      pie: {
        expandOnClick: false,
        donut: {
          labels: {
            show: false,
            name:{
              fontSize: "14px",
              fontWeight: "400",
              color: text_color,
              show: false
            },
            value: {
              fontSize: "14px",
              fontWeight: "400",
              color: text_color,
              show: true
            },
            total: {
              fontSize: "14px",
              fontWeight: "400",
              color: text_color,
              show: false
            }
          }
        }
      }
    },
    fill: {
      colors: ['#f44336', '#4CAF50'],
    },
    tooltip: {
      enabled: false,
      theme: "dark",
    },
  }
}

export const pieChartOptions =  function (labels, text_color){
  return {
    labels: labels,
    colors: ['#da991f', '#027ddf', "#38B2AC"],
    chart: {
      width: "90px",
    },
    stroke: {
      show: false
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    legend: {
      show: true,
      labels: {
        colors: text_color,
        fontSize: "14px",
        fontWeight: "500",
      },
    },
    dataLabels: {
      enabled: true,
      dropShadow: {
        enabled: false,
      },
      style:{
        fontSize: "14px",
        fontWeight: "400",
        colors: [text_color]
      }
    },
    hover: { mode: null },
    plotOptions: {
      donut: {
        expandOnClick: false,
        donut: {
          labels: {
            show: true,

          },
        },
      },
    },
    fill: {
      colors: ['#da991f', '#027ddf', "#38B2AC"],
    },
    tooltip: {
      enabled: true,
      theme: "dark",
    },
  }
};

export const pieChartNoFillOptions =  function (labels, text_color){
  let pieOptions = {
    labels: labels,
    chart: {
      width: "90px",
    },
    stroke: {
      show: false
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    legend: {
      show: true,
      position: 'bottom',
      labels: {
        colors: text_color,
        fontSize: "14px",
        fontWeight: "500",
      },
    },
    dataLabels: {
      enabled: true,
      dropShadow: {
        enabled: false,
      },
      style:{
        fontSize: "14px",
        fontWeight: "400",
        colors: [text_color]
      }
    },
    hover: { mode: null },
    plotOptions: {
      donut: {
        expandOnClick: false,
        donut: {
          labels: {
            show: true,

          },
        },
      },
    },
    tooltip: {
      enabled: true,
      theme: "dark",
    },
  }
  return pieOptions;
};

export const pieChartData = [63, 25];

// Total Spent Default

export const lineChartDataTotalSpent = [
  {
    name: "Revenue",
    data: [50, 64, 48, 66, 49, 68],
  },
  {
    name: "Profit",
    data: [30, 40, 24, 46, 20, 46],
  },
];

export const lineChartOptionsTotalSpent = {
  chart: {
    toolbar: {
      show: false,
    },
    dropShadow: {
      enabled: true,
      top: 13,
      left: 0,
      blur: 10,
      opacity: 0.1,
      color: "#4318FF",
    },
  },
  colors: ["#4318FF", "#39B8FF"],
  markers: {
    size: 0,
    colors: "white",
    strokeColors: "#7551FF",
    strokeWidth: 3,
    strokeOpacity: 0.9,
    strokeDashArray: 0,
    fillOpacity: 1,
    discrete: [],
    shape: "circle",
    radius: 2,
    offsetX: 0,
    offsetY: 0,
    showNullDataPoints: true,
  },
  tooltip: {
    theme: "dark",
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
    type: "line",
  },
  xaxis: {
    type: "numeric",
    categories: ["SEP", "OCT", "NOV", "DEC", "JAN", "FEB"],
    labels: {
      style: {
        colors: "#A3AED0",
        fontSize: "12px",
        fontWeight: "500",
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: false,
  },
  legend: {
    show: false,
  },
  grid: {
    show: false,
    column: {
      color: ["#7551FF", "#39B8FF"],
      opacity: 0.5,
    },
  },
  color: ["#7551FF", "#39B8FF"],
};
