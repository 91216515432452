import axios from "axios";
import {getHost} from "./helpers";

export function getParameterByName(name, url = window.location.href) {
  const queryString = url.split('?')[1];
  const params = {};
  if (queryString) {
    queryString.split('&').forEach((param) => {
      const [key, value] = param.split('=');
      params[key] = decodeURIComponent(value);
    });
  }
  return params[name];
}
export function getMembers(){
    return axios.get(`${getHost()}/members`)
}

export function getMandals(ac_no){
    return axios.get(`${getHost()}/mandals/${ac_no}`)
}

export function getAssembly(){
    return axios.get(`${getHost()}/assembly/`)
}

export function getMandalDetailsById(ac_no, mandal_name){
    return axios.post(`${getHost()}/mandal/detail/`, {
        'ac_no': ac_no,
        'mandal_name': mandal_name
    })
}

export function getACDetailsById(ac_no, mandal_name){
    return axios.post(`${getHost()}/assembly/detail/`, {
        'ac_no': ac_no
    })
}


export function getVillageDetailsById(ac_no, mandal_name, village_name){
    return axios.post(`${getHost()}/village/detail/`, {
        'ac_no': ac_no,
        'mandal_name': mandal_name,
        'village_name': village_name
    })
}


export function getBoothDetailsById(ac_no, mandal_name, village_name, booth_id){
    return axios.post(`${getHost()}/booth/detail/`, {
        'ac_no': ac_no,
        'mandal_name': mandal_name,
        'village_name': village_name,
        'booth_id': booth_id
    })
}

export function getVillages(ac_no, mandalName){
    return axios.get(`${getHost()}/villages/${ac_no}?mandal_name=${mandalName}`)
}

export function getBooths(ac_no, mandalName, villageName){
    return axios.get(`${getHost()}/booths/${ac_no}?mandal_name=${mandalName}&village_name=${villageName}`)
}

export function getMemberById(member_id, name){
    return axios.post(`${getHost()}/candidate/`, {
        'member_id': member_id,
        'candidate_name': name
    })
}

export function getCandidateFilters(){
    return axios.get(`${getHost()}/candidate-search/filters`)
}

export function getCandidatesSearch(request){
    return axios.post(`${getHost()}/candidate-search`, request);
}

export function downloadCandidateReport(request){
    return axios.post(`${getHost()}/performance-filter/report/download`, request, {
        responseType: 'blob',
      });
}


export function fetchData(conversation) {
    try {
        return axios.post(`${getHost()}/chat`, {
            'chat': conversation
        })
    } catch (error) {
        return {data: "Oh no I don't know what to say!"}
    }
}
