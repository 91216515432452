import React from "react";
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import banner from "assets/img/auth/banner1.png";

import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";

function SignIn() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const [show, setShow] = React.useState(false);
  const [isFocused, setFocus] = React.useState();
  const [emailError, setEmailError] = React.useState(false);
  const [passwordError, setPasswordError] = React.useState(false);
  const [email, setEmail] = React.useState();
  const [password, setPassword] = React.useState();
  const [error, setError] = React.useState();

  const onButtonClick = () => {
    // Set initial error values to empty
    setEmailError(false);
    setPasswordError(false);

    // Check if the user has entered both fields correctly
    if ('' === email) {
      setEmailError(true)
      setError('Please enter your email')
      return
    }

    if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      setEmailError(true)
      setError('Please enter a valid email')
      return
    }

    if ('' === password) {
      setPasswordError(true)
      setError('Please enter a password')
      return
    }

    if (password.length < 7) {
      setPasswordError(true);
      setError('The password must be 8 characters or longer');
      return
    }
    if ((email === 'admin@rigastech.com' || email === 'admin@tdpparty.com')  && password === 'Admin123'){
      window.location.href = `#/admin/default?ac_no=1`
      window.location.reload();
    } else {
      setEmailError(true);
      setPasswordError(true);
      setError('Please check your email or password');
    }
    localStorage.setItem('user', email);
    // Authentication calls will be made here...
  }
  const handleClick = () => setShow(!show);
  return (
      <DefaultAuth illustrationBackground={banner} image={banner}>
        <Flex
            maxW={{ base: "100%", md: "max-content" }}
            w='100%'
            mx={{ base: "auto", lg: "0px" }}
            me='auto'
            h='100%'
            alignItems='start'
            justifyContent='center'
            mb={{ base: "30px", md: "60px" }}
            px={{ base: "25px", md: "0px" }}
            mt={{ base: "40px", md: "14vh" }}
            flexDirection='column'>
          <Box me='auto'>
            <Heading color={textColor} fontSize='36px' mb='10px'>
              Sign In
            </Heading>
            <Text
                mb='36px'
                ms='4px'
                color={textColorSecondary}
                fontWeight='400'
                fontSize='md'>
              Enter your email and password to sign in!
            </Text>
          </Box>
          <Flex
              zIndex='2'
              direction='column'
              w={{ base: "100%", md: "420px" }}
              maxW='100%'
              background='transparent'
              borderRadius='15px'
              mx={{ base: "auto", lg: "unset" }}
              me='auto'
              mb={{ base: "20px", md: "auto" }}>
            {/*<Button
            fontSize='sm'
            me='0px'
            mb='26px'
            py='15px'
            h='50px'
            borderRadius='16px'
            bg={googleBg}
            color={googleText}
            fontWeight='500'
            _hover={googleHover}
            _active={googleActive}
            _focus={googleActive}>
            <Icon as={FcGoogle} w='20px' h='20px' me='10px' />
            Sign in with Google
          </Button>
          <Flex align='center' mb='25px'>
            <HSeparator />
            <Text color='gray.400' mx='14px'>
              or
            </Text>
            <HSeparator />
          </Flex>*/}
            <FormControl>
              <FormLabel
                  display='flex'
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  mb='8px'>
                Email<Text color={'#da991f'}>*</Text>
              </FormLabel>
              <Input
                  isRequired={true}
                  variant='auth'
                  fontSize='sm'
                  ms={{ base: "0px", md: "0px" }}
                  type='email'
                  placeholder='mail@mail.com'
                  onChange={(ev) => setEmail(ev.target.value)}
                  mb='24px'
                  borderColor={emailError ? 'red.500': null}
                  fontWeight='500'
                  size='lg'
                  onKeyDown={(e) => {
                    if (e.key === "Enter")
                      onButtonClick();
                  }}
              />
              <FormLabel
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  display='flex'>
                Password<Text color={'#da991f'}>*</Text>
              </FormLabel>
              <InputGroup size='md'>
                <Input
                    isRequired={true}
                    fontSize='sm'
                    placeholder='Min. 8 characters'
                    onChange={(ev) => setPassword(ev.target.value)}
                    mb='24px'
                    size='lg'
                    borderColor={passwordError ? 'red.500': null}
                    type={show ? "text" : "password"}
                    variant='auth'
                    onKeyDown={(e) => {
                      if (e.key === "Enter")
                        onButtonClick();
                    }}
                />
                <InputRightElement display='flex' alignItems='center' mt='4px'>
                  <Icon
                      color={textColorSecondary}
                      _hover={{ cursor: "pointer" }}
                      as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                      onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>
              <Flex justifyContent='space-between' align='center' mb='24px'>
                <FormControl display='flex' alignItems='center'>
                  <Checkbox
                      id='remember-login'
                      colorScheme='brandScheme'
                      me='10px'
                  />
                  <FormLabel
                      htmlFor='remember-login'
                      mb='0'
                      fontWeight='normal'
                      color={textColor}
                      fontSize='sm'>
                    Keep me logged in
                  </FormLabel>
                </FormControl>
                <NavLink to='/auth/forgot-password'>
                  <Text
                      color={textColorBrand}
                      fontSize='sm'
                      w='124px'
                      fontWeight='500'>
                    Forgot password?
                  </Text>
                </NavLink>
              </Flex>
              <Button
                  fontSize='sm'
                  variant='brand'
                  fontWeight='1000'
                  style={{
                    backgroundColor: isFocused ? "#FFEA00" : "#F4BB44"
                  }}
                  onMouseEnter={() => setFocus(true)}
                  onMouseLeave={() => setFocus(false)}
                  onClick={onButtonClick}
                  w='100%'
                  h='50'
                  mb='24px'>
                Sign In
              </Button>
            </FormControl>
            <Flex
                flexDirection='column'
                justifyContent='center'
                alignItems='start'
                maxW='100%'
                mt='0px'>
              { error?
                  <Text color={"red.500"} fontWeight='400' fontSize='14px'>
                    {error}
                  </Text>:null
              }
              <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
                Not registered yet?
                <NavLink to='/auth/sign-up'>
                  <Text
                      color={textColorBrand}
                      as='span'
                      ms='5px'
                      fontWeight='500'>
                    Create an Account
                  </Text>
                </NavLink>
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </DefaultAuth>
  );
}

export default SignIn;
