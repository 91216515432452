export const API_HOST = "http://localhost:8000"

export const serverIP = window.location.hostname.includes('localhost') ? 'localhost:3000': window.location.hostname;

export function getHost() {
    if (!window.location.hostname.includes('localhost') && serverIP && (serverIP.length > 0)) {
        return `https://${serverIP}/api`;
    } else {
        return `http://${serverIP}/api`;
    }
}