// Chakra imports
import {Box, Button, Grid, Text, useColorModeValue} from "@chakra-ui/react";
import {React, useEffect, useState} from "react";
import Select from 'react-select';
import ComplexTable from "../default/components/ComplexTable";
import {
    communityLeadersDataTable,
} from "../default/variables/columnsData";
import {downloadCandidateReport, getCandidateFilters, getCandidatesSearch} from "../../../api/api-helpers";


export default function Overview() {
    const [results, setResults] = useState();
    const [casteFilter, setCasteFilter] = useState();
    const [committeeLevelFilter, setCommitteeLevelFilter] = useState();
    const [constituencyNameFilter, setConstituencyNameFilter] = useState();
    const [casteSelected, setCasteSelected] = useState();
    const [constituencySelected, setConstituencySelected] = useState();
    const [committeeSelected, setCommitteeSelected] = useState();
    const [user, setUser] = useState();
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");


    const textColor = useColorModeValue("white", "white");

    const onChange = (
        value,
        actionMeta
    ) => {
        let filter = value.map(a => a.value);
        if (actionMeta.name === 'caste'){
            setCasteSelected(filter);
        } else if (actionMeta.name === 'constituency') {
            setConstituencySelected(filter);
        } else {
            setCommitteeSelected(filter);
        }
    };

    const onFilter = async () => {
        setResults([]);
        console.log(`caste: ${casteSelected}`)
        console.log(`constituency: ${constituencySelected}`)
        console.log(`committee: ${committeeSelected}`)

        const candidateSearch = await getCandidatesSearch({
            'committee': committeeSelected,
            'constituency': constituencySelected,
            'caste': casteSelected,
            'limit': 50
        });
        let results = candidateSearch.data.results;
        setResults(results);
    }

    const onDownload = async () => {
        let loggedUser = localStorage.getItem('user')
        setUser(loggedUser);
        const downloadReport = await downloadCandidateReport({
            'committee': committeeSelected,
            'constituency': constituencySelected,
            'caste': casteSelected,
            'limit': -1
        });
        const url = window.URL.createObjectURL(new Blob([downloadReport.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'performance-report.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }


    useEffect(() => {
        const fetchFilter = async () => {
            try {
                const response = await getCandidateFilters();
                let filters = response.data.results;
                filters.forEach((row) => {
                    if (row['filter_type'] === 'caste'){
                        setCasteFilter(JSON.parse(row.filter))
                    } else if (row['filter_type'] === 'committee_type'){
                        setCommitteeLevelFilter(JSON.parse(row.filter));
                    }
                    else if (row['filter_type'] === 'constituency'){
                        setConstituencyNameFilter(JSON.parse(row.filter));
                    }
                })
                setResults(results);
            } catch (error) {
                console.error('Error fetching filters:', error);
            }
        };
        fetchFilter();
    }, []);

    useEffect(() => {
        const fetchCandidateDetails = async () => {
            try {
                const candidateSearch = await getCandidatesSearch({
                    'committee': committeeSelected,
                    'constituency': constituencySelected,
                    'caste': casteSelected,
                    'limit': 50
                });
                let results = candidateSearch.data.results;
                setResults(results);
            } catch (error) {
                console.error('Error fetching candidates:', error);
            }
        };
        fetchCandidateDetails();
    }, []);

    return (
        <div id="candidate-search">
            <Box pt={{base: "50px", md: "50px", xl: "50px"}}>
                { constituencyNameFilter && casteFilter && committeeLevelFilter ?
                    <Grid ml="5px" mb='20px'
                          templateColumns={{
                              base: "1fr",
                              lg: "repeat(4, 1fr)"
                          }}
                          templateRows={{
                              base: "repeat(4, 1fr)",
                              lg: "1fr",
                          }}
                          gap={{base: "20px", xl: "20px"}}>

                        <Select
                            isMulti
                            name="constituency"
                            styles={{
                                control: (provided, state) => ({
                                    ...provided,
                                    "borderColor": borderColor
                                }),
                            }}
                            options={constituencyNameFilter}
                            onChange={onChange}
                            placeholder={'Select Constituency'}
                            className="basic-multi-select"
                            classNamePrefix="constituency"
                        />
                        <Select
                            isMulti
                            name="caste"
                            styles={{
                                control: (provided, state) => ({
                                    ...provided,
                                    "borderColor": borderColor
                                }),
                            }}
                            options={casteFilter}
                            onChange={onChange}
                            placeholder={'Select Caste'}
                            className="basic-multi-select"
                            classNamePrefix="caste"
                        />
                        <Select
                            isMulti
                            name="committee level"
                            styles={{
                                control: (provided, state) => ({
                                    ...provided,
                                    "borderColor": borderColor
                                }),
                            }}
                            options={committeeLevelFilter}
                            onChange={onChange}
                            placeholder={'Select Committee'}
                            className="basic-multi-select"
                            classNamePrefix="committee level"
                        />
                        <div>
                            <Button colorScheme="brand" mr={3}  onClick={onFilter} style={{width: "100px"}}>
                                <Text fontSize='md' color={textColor} fontWeight='700'>
                                    Filter
                                </Text>
                            </Button>
                            { localStorage.getItem('user') !== 'admin@tdpparty.com' ? <Button colorScheme="brand" mr={3}  onClick={onDownload} style={{width: "100px"}}>
                                <Text fontSize='md' color={textColor} fontWeight='700'>
                                    Export
                                </Text>
                            </Button> : null}
                        </div>
                    </Grid>
                    :null}
                {results ?
                    <ComplexTable
                        name={"Candidates"}
                        showName={false}
                        maxHieght={"1500px"}
                        columnsData={communityLeadersDataTable}
                        tableData={results}
                    /> : <div align={"center"}>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <iframe
                            src="https://lottie.host/embed/c698f656-918b-4a16-8678-f1b7bc4a79f3/n1SzSdcaUG.json"></iframe>
                        <br/>
                    </div>}

            </Box>
        </div>
    );
}
