import React from "react";
import ReactApexChart from "react-apexcharts";

class DonutChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chartData: [],
      chartOptions: {},
    };
  }

  componentDidMount() {
    this.setState({
      chartData: this.props.chartData,
      chartOptions: this.props.chartOptions,
    });
  }

  render() {
    return (
        <ReactApexChart
            options={this.state.chartOptions}
            series={this.state.chartData}
            type={'donut'}
            width='100%'
            height='85%'
        />)
  }
}

export default DonutChart;
