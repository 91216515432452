// Chakra imports
import {Box, Grid, Text, useColorModeValue} from "@chakra-ui/react";
// Assets
import {React, useEffect, useState} from "react";
import ComplexTable from "../default/components/ComplexTable";
import {
    historicMandalElectionResultsData, historicTopPerformingBoothsResultsData,
    historicTopPerformingMandalsResultsData,
    historicTopPerformingVillagesResultsData,
    leaderFeedbackData,
    mandalElectionResultsData,
    topPerformingBoothsResultsData,
    topPerformingMandalsResultsData,
    topPerformingVillagesResultsData
} from "../default/variables/columnsData";
import PieCard from "../default/components/PieCard";
import {getMandalDetailsById, getParameterByName} from "../../../api/api-helpers";
import {Banner} from "../profile/components/Storage"
import General from "../profile/components/General";

export default function Overview() {
    let acNo = getParameterByName('ac_no');
    let mandalName = getParameterByName('mandal_name');
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const [acNO, setAcNO] = useState();
    const [mandal, setMandal] = useState();
    const [constituency, setConstituency] = useState();
    const [electionResults, setElectionResults] = useState();
    const [historicalResults, setHistoricalResults] = useState();
    const [historicalDefCharts, setHistoricalDefCharts] = useState();
    const [topVillages, setTopVillages] = useState();
    const [topBooths, setTopBooth] = useState();
    const [historicTopBooths, setHistoricTopBooth] = useState();
    const [historicTopVillages, setHistoricTopVillages] = useState();
    const [electionDefCharts, setElectionDefCharts] = useState();
    const [labels, setLabels] = useState();
    const [feedback, setFeedback] = useState();
    const [voteDistribution, setVoteDistribution] = useState();
    const [mandalLevelVotes, setMandalLevelVotes] = useState();
    const [mandalLabels, setMandalLabels] = useState();



    useEffect(() => {
        const fetchCandidateDetails = async () => {
            try {
                const response = await getMandalDetailsById(acNo, mandalName);
                let mandal = response.data;
                setAcNO(acNo);
                let acResults  = mandal['election_results'];
                let historicResults = mandal['historic_results'];
                let voteDistribution = mandal['vote_distribution'];
                if (acResults && acResults.length > 0){
                    let electionCharts = [];
                    acResults = acResults[0];
                    electionCharts.push(acResults['mandal_tdp_votes']);
                    electionCharts.push(acResults['mandal_ysrc_votes']);
                    electionCharts.push(acResults['mandal_total_polled'] - acResults['mandal_tdp_votes'] - acResults['mandal_ysrc_votes']);
                    setElectionDefCharts(electionCharts);
                }
                if (voteDistribution && voteDistribution.length > 0) {
                    let mandalLabels = [], tdp_data = [] , ysrc_data = [], others_data= [], total_votes = [];
                    for(let i of voteDistribution){
                        mandalLabels.push(i['village']);
                        tdp_data.push(i['village_tdp_prc']);
                        ysrc_data.push(i['village_ycp_prc']);
                        let num = 100 - i['village_tdp_prc'] - i['village_ycp_prc'];
                        total_votes.push(i['village_total_polled']);
                        others_data.push(Math.round(num * 100) / 100);
                        setMandalLevelVotes(total_votes);
                    }
                    setVoteDistribution([
                        {'name': 'TDP Vote', 'data': tdp_data},
                        {'name': 'YSRC Vote', 'data': ysrc_data},
                        {'name': 'Others Vote', 'data': others_data}
                    ]);
                    setMandalLabels(mandalLabels);
                }
                setElectionResults(mandal['election_results']);
                setHistoricalResults(mandal['historic_results']);
                setConstituency(mandal['mandal_name']);
                setFeedback(mandal['feedback']);
                if (historicResults && historicResults.length > 0) {
                    let yearLabels = [], tdp_data = [] , ysrc_data = [], others_data= [];
                    for(let i of historicResults){
                        yearLabels.push(i['year'])
                        tdp_data.push(i['mandal_tdp_prc'])
                        ysrc_data.push(i['mandal_ycp_prc']);
                        let num = 100 - i['mandal_tdp_prc'] - i['mandal_ycp_prc'];
                        others_data.push(Math.round(num * 100) / 100);
                    }
                    setHistoricalDefCharts([
                        {'name': 'TDP Vote', 'data': tdp_data},
                        {'name': 'YSRC Vote', 'data': ysrc_data},
                        {'name': 'Others Vote', 'data': others_data}
                    ]);
                    setLabels(yearLabels);
                }
                setTopVillages(mandal['top_villages']);
                setMandal(mandal);
                setFeedback(mandal['feedback_details'])
                setTopVillages(mandal['top_villages']);
                setTopBooth(mandal['top_booths']);
                setHistoricTopVillages(mandal['historic_top_villages']);
                setHistoricTopBooth(mandal['historic_top_booths']);
            } catch (error) {
                console.error('Error fetching candidates:', error);
            }
        };
        fetchCandidateDetails();
    }, []);
    const textColorPrimary = useColorModeValue("secondaryGray.900", "white");

    return (
        <div id="candidate-profile">
            {mandal ?
                <Box pt={{base: "130px", md: "80px", xl: "80px"}}>
                    <Grid
                        templateColumns={{
                            base: "0fr",
                            lg: "1.34fr 1fr 1.62fr",
                        }}
                        templateRows={{
                            base: "repeat(3, 1fr)",
                            lg: "1fr",
                        }}
                        gap={{base: "20px", xl: "20px"}}>
                        <Banner  gridArea='1 / 1 / 2 / 2' name={mandalName}
                                 assembly={mandal['assembly']}
                                 parliament={mandal['parliament']}
                                 grade={mandal['grading']}
                                 gain={mandal['mandal_gain']}
                                 gain_prc={mandal['mandal_gain_prc']}
                        />
                        {electionDefCharts ?
                            <PieCard gridArea={{base: "2 / 1 / 2 / 2", lg: "1 / 2 / 2 / 5"}}
                                     name={`Election Results -  ${constituency}`} type={"donut"} results={electionDefCharts}
                                     labels={['TDP', 'YSRC', 'Others']}/> :
                            null
                        }
                    </Grid>
                    <br/>
                    {electionResults ?
                        <ComplexTable
                            name={`Election Results - ${mandalName}`}
                            acNo={acNo}
                            columnsData={mandalElectionResultsData}
                            tableData={electionResults}
                        /> : null}
                    <br/>
                    <Grid
                        mb='20px'
                        templateColumns={{
                            base: "1fr",
                            lg: "repeat(2, 1fr)",
                            "2xl": "1.34fr 1.62fr 1fr",
                        }}
                        templateRows={{
                            base: "repeat(3, 1fr)",
                            lg: "1fr",
                        }}
                        gap={{base: "20px", xl: "20px"}}>

                        {voteDistribution ?
                            <PieCard gridArea={{base: "1 / 1 / 2 / 2"}}
                                     name={`Election Results -  ${constituency}`} type={"bar"}
                                     results={voteDistribution}
                                     labels={mandalLabels}
                            /> :
                            null
                        }
                        {mandalLevelVotes ?
                            <PieCard gridArea={{base: "2 / 1 / 2 / 2", lg: "1 / 2 / 2 / 5"}}
                                     name={`Vote Distibution -  ${constituency}`} type={"pie"}
                                     results={mandalLevelVotes}
                                     labels={mandalLabels} type_pie={"other_pie"}
                            /> :
                            null
                        }
                    </Grid>
                    <div id={'election-charts'}>
                        <Grid
                            mb='20px'
                            templateColumns={{
                                base: "1fr",
                                lg: "repeat(2, 1fr)",
                                "2xl": "1.34fr 1.62fr 1fr",
                            }}
                            templateRows={{
                                base: "repeat(2, 1fr)",
                                lg: "1fr",
                            }}
                            gap={{base: "20px", xl: "20px"}}>

                            {historicalDefCharts ?
                                <PieCard gridArea={{base: "1 / 1 / 2 / 5"}}
                                         name={`Historical Results -  ${constituency}`} type={"bar"} results={historicalDefCharts}
                                         labels={labels}
                                /> :
                                null
                            }
                        </Grid>
                        {historicalResults && historicalResults.length > 0 ?
                            <div><ComplexTable
                                name={"Historical Results Summary"}
                                columnsData={historicMandalElectionResultsData}
                                tableData={historicalResults}
                            />
                                <br/></div> : null}
                        <div>
                            <br/>
                            <hr/>
                            <Text
                                align={"center"}
                                color={textColorPrimary}
                                fontWeight='bold'
                                fontSize='2xl'
                                mt='10px'
                                mb='4px'>
                                Mandal Performance
                            </Text>
                            <hr/>
                            <Text
                                align={"center"}
                                color={textColorPrimary}
                                fontWeight='bold'
                                fontSize='2xl'
                                mt='10px'
                                mb='4px'>
                                2024 Performance
                            </Text>
                            <hr/>
                            <br/>
                        </div>
                        <Grid
                            mb='20px'
                            templateColumns={{
                                base: "1fr",
                                lg: "repeat(2, 1fr)",
                            }}
                            templateRows={{
                                base: "1fr",
                            }}
                            gap={{base: "20px", xl: "20px"}}>

                            {topVillages ?
                                <ComplexTable
                                    name={"Villages"}
                                    columnsData={topPerformingVillagesResultsData}
                                    acNo={acNO}
                                    showLink={true}
                                    tableData={topVillages}
                                /> : null}
                            {topBooths ?
                                <ComplexTable
                                    name={"Booths"}
                                    columnsData={topPerformingBoothsResultsData}
                                    acNo={acNO}
                                    showLink={true}
                                    tableData={topBooths}
                                /> : null}
                        </Grid>
                        <div>
                            <br/>
                            <hr/>
                            <Text
                                align={"center"}
                                color={textColorPrimary}
                                fontWeight='bold'
                                fontSize='2xl'
                                mt='10px'
                                mb='4px'>
                                Historical Performance
                            </Text>
                            <hr/>
                            <br/>
                        </div>
                        <Grid
                            mb='20px'
                            templateColumns={{
                                base: "1fr",
                                lg: "repeat(2, 1fr)",
                            }}
                            templateRows={{
                                base: "1fr"
                            }}
                            gap={{base: "20px", xl: "20px"}}>

                            {historicTopVillages ?
                                <ComplexTable
                                    name={"Villages"}
                                    columnsData={historicTopPerformingVillagesResultsData}
                                    acNo={acNO}
                                    showLink={true}
                                    tableData={historicTopVillages}
                                /> : null}
                            {historicTopBooths ?
                                <ComplexTable
                                    name={"Booths"}
                                    columnsData={historicTopPerformingBoothsResultsData}
                                    acNo={acNO}
                                    tableData={historicTopBooths}
                                /> : null}
                        </Grid>
                        <br/>
                        <div>
                            <br/>
                            <hr/>
                            <Text
                                align={"center"}
                                color={textColorPrimary}
                                fontWeight='bold'
                                fontSize='2xl'
                                mt='10px'
                                mb='4px'>
                                CUBS Feedback
                            </Text>
                            <hr/>
                            <br/>
                        </div>
                    </div>
                    {feedback ?
                        <ComplexTable
                            name={"CUBS Feedback"}
                            columnsData={leaderFeedbackData}
                            tableData={feedback}
                        /> : null}
                </Box> : <div align={"center"}>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <iframe src="https://lottie.host/embed/c698f656-918b-4a16-8678-f1b7bc4a79f3/n1SzSdcaUG.json"></iframe>
                    <br/>

                </div>}
        </div>
    );
}
