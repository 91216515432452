// Chakra imports
import {Box, Grid, Text, useColorModeValue} from "@chakra-ui/react";
// Assets
import {React, useEffect, useState} from "react";
import ComplexTable from "../default/components/ComplexTable";
import {
  historicTopPerformingBoothsResultsData,
  historicVillageElectionResultsData,
  leaderFeedbackData, leaderFeedbackVillageData,
  mandalElectionResultsData,
  topPerformingBoothsResultsData,
  topPerformingVillagesResultsData,
  villageElectionResultsData
} from "../default/variables/columnsData";
import PieCard from "../default/components/PieCard";
import {getParameterByName, getVillageDetailsById} from "../../../api/api-helpers";
import {Banner} from "../profile/components/Storage"
import General from "../profile/components/General";

export default function Overview() {
  let acNo = getParameterByName('ac_no');
  let mandalName = getParameterByName('mandal_name');
  let villageName = getParameterByName('village_name');
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [acNO, setAcNO] = useState();
  const [village, setVillage] = useState();
  const [constituency, setConstituency] = useState();
  const [electionResults, setElectionResults] = useState();
  const [historicalResults, setHistoricalResults] = useState();
  const [historicalDefCharts, setHistoricalDefCharts] = useState();
  const [topBooths, setTopBooths] = useState();
  const [historicTopBooths, setHistoricTopBooth] = useState();
  const [electionDefCharts, setElectionDefCharts] = useState();
  const [labels, setLabels] = useState();
  const [feedback, setFeedback] = useState();


  useEffect(() => {
    const fetchCandidateDetails = async () => {
      try {
        const response = await getVillageDetailsById(acNo, mandalName, villageName);
        let village = response.data;
        setAcNO(acNo);
        let acResults  = village['election_results'];
        let historicResults = village['historic_results'];
        if (acResults && acResults.length > 0){
          let electionCharts = [];
          acResults = acResults[0];
          electionCharts.push(acResults['village_tdp_votes']);
          electionCharts.push(acResults['village_ysrc_votes']);
          electionCharts.push(acResults['village_total_polled'] - acResults['village_tdp_votes'] - acResults['village_ysrc_votes']);
          setElectionDefCharts(electionCharts);
        }
        setElectionResults(village['election_results']);
        setHistoricalResults(village['historic_results']);
        setConstituency(village['village_name']);
        setFeedback(village['feedback']);
        if (historicResults && historicResults.length > 0) {
          let yearLabels = [], tdp_data = [] , ysrc_data = [], others_data= [];
          for(let i of historicResults){
            yearLabels.push(i['year'])
            tdp_data.push(i['village_tdp_prc'])
            ysrc_data.push(i['village_ycp_prc']);
            let num = 100 - i['village_tdp_prc'] - i['village_ycp_prc'];
            others_data.push(Math.round(num * 100) / 100);
          }
          setHistoricalDefCharts([
            {'name': 'TDP Vote', 'data': tdp_data},
            {'name': 'YSRC Vote', 'data': ysrc_data},
            {'name': 'Others Vote', 'data': others_data}
          ]);
          setLabels(yearLabels);
        }
        setTopBooths(village['top_booths']);
        setVillage(village);
        setFeedback(village['feedback_details']);
        setHistoricTopBooth(village['historic_top_booths']);
      } catch (error) {
        console.error('Error fetching candidates:', error);
      }
    };
    fetchCandidateDetails();
  }, []);
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");

  return (
      <div id="candidate-profile">
        {village ?
            <Box pt={{base: "50px", md: "50px", xl: "50px"}}>
              <Grid
                  templateColumns={{
                    base: "0fr",
                    lg: "1.34fr 1fr 1.62fr",
                  }}
                  templateRows={{
                    base: "repeat(3, 1fr)",
                    lg: "1fr",
                  }}
                  gap={{base: "20px", xl: "20px"}}>
                <Banner  gridArea='1 / 1 / 2 / 2'
                         name={village['village_name']}
                         mandalName={mandalName}
                         assembly={village['assembly']}
                         parliament={village['parliament']}
                         grade={village['grading']}
                         gain={village['tdp_gain']}
                         gain_prc={village['tdp_gain_prc']}
                />
                {electionDefCharts ?
                    <PieCard gridArea={{base: "2 / 1 / 2 / 2", lg: "1 / 2 / 2 / 5"}}
                             name={`Election Results -  ${constituency}`} type={"donut"} results={electionDefCharts}
                             labels={['TDP', 'YSRC', 'Others']}/> :
                    null
                }
              </Grid>
              <br/>
              {electionResults ?
                  <ComplexTable
                      name={`Election Results - ${constituency}`}
                      acNo={acNo}
                      columnsData={villageElectionResultsData}
                      tableData={electionResults}
                  /> : null}
              <br/>
              <div id={'election-charts'}>
                <Grid
                    mb='20px'
                    templateColumns={{
                      base: "1fr",
                      lg: "repeat(2, 1fr)",
                      "2xl": "1.34fr 1.62fr 1fr",
                    }}
                    templateRows={{
                      base: "repeat(3, 1fr)",
                      lg: "1fr",
                    }}
                    gap={{base: "20px", xl: "20px"}}>

                  {historicalDefCharts && historicalResults.length > 0 ?
                      <PieCard gridArea={{base: "1 / 1 / 2 / 5"}}
                               name={`Historical Results -  ${constituency}`} type={"bar"} results={historicalDefCharts}
                               labels={labels}
                      /> :
                      null
                  }
                </Grid>
                {historicalResults && historicalResults.length > 0 ?
                    <div><ComplexTable
                        name={"Historical Results Summary"}
                        columnsData={historicVillageElectionResultsData}
                        tableData={historicalResults}
                    />
                      <br/></div> : null}
                <div>
                  <br/>
                  <hr/>
                  <Text
                      align={"center"}
                      color={textColorPrimary}
                      fontWeight='bold'
                      fontSize='2xl'
                      mt='10px'
                      mb='4px'>
                    Village Performance
                  </Text>
                  <hr/>
                  <Text
                      align={"center"}
                      color={textColorPrimary}
                      fontWeight='bold'
                      fontSize='2xl'
                      mt='10px'
                      mb='4px'>
                    2024 Performance
                  </Text>
                  <hr/>
                  <br/>
                </div>
                {topBooths ?
                    <ComplexTable
                        name={"Booths"}
                        columnsData={topPerformingBoothsResultsData}
                        mandalName={mandalName}
                        showLink={true}
                        villageName={village['village_name']}
                        acNo={acNO}
                        tableData={topBooths}
                    /> : null}
                <div>
                  <br/>
                  <hr/>
                  <Text
                      align={"center"}
                      color={textColorPrimary}
                      fontWeight='bold'
                      fontSize='2xl'
                      mt='10px'
                      mb='4px'>
                    Historical Performance
                  </Text>
                  <hr/>
                  <br/>
                </div>
                {historicTopBooths ?
                    <ComplexTable
                        name={"Booths"}
                        columnsData={historicTopPerformingBoothsResultsData}
                        mandalName={mandalName}
                        villageName={village['village_name']}
                        acNo={acNO}
                        tableData={historicTopBooths}
                    /> : null}
                <br/>
                <div>
                  <br/>
                  <hr/>
                  <Text
                      align={"center"}
                      color={textColorPrimary}
                      fontWeight='bold'
                      fontSize='2xl'
                      mt='10px'
                      mb='4px'>
                    CUBS Feedback
                  </Text>
                  <hr/>
                  <br/>
                </div>
              </div>
              {feedback ?
                  <ComplexTable
                      name={"CUBS Feedback"}
                      columnsData={leaderFeedbackVillageData}
                      tableData={feedback}
                  /> : null}
            </Box> : <div align={"center"}>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <iframe src="https://lottie.host/embed/c698f656-918b-4a16-8678-f1b7bc4a79f3/n1SzSdcaUG.json"></iframe>
              <br/>

            </div>}
      </div>
  );
}
