// Chakra imports
import {Box, Grid, Text, useColorModeValue} from "@chakra-ui/react";

// Custom components
import Banner from "views/admin/profile/components/Banner";
import General from "views/admin/profile/components/General";

// Assets
import banner from "assets/img/auth/banner1.png";
import {React, useEffect, useState} from "react";
import ComplexTable from "../default/components/ComplexTable";
import {
  historicalResultsData,
  topPerformingMandalsResultsData,
  surveyResultsData,
  generalKPIMetricsData,
  form12DData,
  homeClusterData,
  resourceDistributionData,
  electionDataActionable,
  cadreDevelopmentDataActionable,
  genericHeaderLessTable,
  programsConductedTable,
  pressMeetsConductedTable,
  aciFeedbackTable,
  communityLeadersTable,
  contributorsTable,
  cubsPerformance,
  super6Outreach,
  poOutreach,
  bsbgOutreach,
  newVoterRegistration,
  electionResults,
  zonalCoordinatorFeedback,
  zonalCoordinatorFeedbackHeader, zonalObserverFeedbackHeader
} from "../default/variables/columnsData";
import PieCard from "../default/components/PieCard";
import {getMemberById} from "../../../api/api-helpers";

function getParameterByName(name, url = window.location.href) {
  const queryString = url.split('?')[1];
  const params = {};
  if (queryString) {
    queryString.split('&').forEach((param) => {
      const [key, value] = param.split('=');
      params[key] = decodeURIComponent(value);
    });
  }
  return params[name];
}

export default function Overview() {
  let id = getParameterByName('id');
  let name = getParameterByName('name');
  if(!id || !name){
    id='#38324292'
    name='Nara Chandrababu Naidu'
  }
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [candidateName, setCandidateName] = useState();
  const [candidateProfile, setCandidateProfile] = useState();
  const [constituency, setConstituency] = useState();
  const [candidateImage, setCandidateImage] = useState();
  const [educationQualification, setEducationQualification] = useState();
  const [zone, setZone] = useState();
  const [acResults, setAcResults] = useState();
  const [zonalCoordinatorFeedback, setZonalCoordinatorFeedback] = useState();
  const [zonalObserverFeedback, setZonalObserverFeedback] = useState();
  const [batch, setBatch] = useState();
  const [attendance, setAttendance] = useState();
  const [caste, setCaste] = useState();
  const [casteCategory, setCasteCategory] = useState();
  const [committee, setCommittee] = useState();
  const [results, setResults] = useState([]);
  const [surveyResults, setSurveyResults] = useState([]);
  const [chartResults, setChartResults] = useState();
  const [labels, setLabels] = useState();
  const [postalAwareness, setPostalAwareness] = useState();
  const [kpiMetrics, setKpiMetrics] = useState();
  const [meetingKpiMetrics, setMeetingKpiMetrics] = useState();
  const [socialMediaMetrics, setSocialMediaMetrics] = useState();
  const [resourceDistribution, setResourceDistribution] = useState();
  const [patAwareness, setPatAwareness] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [partyPosition, setPartyPosition] = useState();
  const [candidatePerformance, setCandidatePerformance] = useState();
  const [observerPerformance, setObserverPerformance] = useState();
  const [cadreDevelopment, setCadreDevelopment] = useState();
  const [pressMeetsConducted, setPressMeetsConducted] = useState();
  const [programsConducted, setProgramsConducted] = useState();
  const [feedbackByACI, setFeedbackByACI] = useState();
  const [cadreACIFeedback, setCadreACIFeedback] = useState();
  const [contributorsACIFeedback, setContributorsACIFeedback] = useState();
  const [summaryCUBSPerformance, setSummaryCUBSPerformance] = useState();
  const [campaignStatus, setCampaignStatus] = useState();
  const [disruptorsFeedback, setDisruptorsFeedback] = useState();
  const [attrocitiesFeedback, setAttrocitiesFeedback] = useState();
  const [grading, setGrading] = useState();
  const [electionDefCharts, setElectionDefCharts] = useState();
  const [performance, setPerformance] = useState();
  const [publicAppearance, setPublicAppearance] = useState();
  const [personality, setPersonality] = useState();
  const [acNo, setAcNo] = useState();
  const [topMandals, setTopMandals] = useState();



  useEffect(() => {
    const fetchCandidateDetails = async () => {
      try {
        const response = await getMemberById(id, name);
        let candidate = response.data;
        setResults(candidate['results']);
        setAcNo(candidate['ac_no']);
        setCampaignStatus(candidate['campaign_status']);
        setSurveyResults(candidate['survey_results']);
        setContributorsACIFeedback(candidate['contributors_feedback']);
        setSummaryCUBSPerformance(candidate['summary_cubs_performance']);
        setAcResults(candidate['ac_results']);
        let acResults  = candidate['ac_results'];
        if (acResults && acResults.length > 0){
          let electionCharts = [];
          acResults = acResults[0];
          electionCharts.push(acResults['nda_vote_prc']);
          electionCharts.push(acResults['ysrc_vote_prc']);
          electionCharts.push(100 - acResults['ysrc_vote_prc'] - acResults['nda_vote_prc']);
          setElectionDefCharts(electionCharts);
        }
        setZonalObserverFeedback(candidate['zonal_observer_feedback']);
        setZonalCoordinatorFeedback(candidate['zonal_coordinator_feedback'])
        setDisruptorsFeedback(candidate['disruptors_feedback']);
        setAttrocitiesFeedback(candidate['attrocities_feedback']);
        setGrading(candidate['grading']);
        setPerformance([
          {
            'key': 'Performance Rating',
            'value': candidate['grading'] && candidate['grading']['candidate_grading_prc'] ? candidate['grading']['candidate_grading_prc'] : '-'
          },
          {
            'key': 'Election Performance',
            'value': candidate['ac_grading'] && candidate['ac_grading']['election_management'] ? candidate['ac_grading']['election_management'] : '-'
          },
          {
            'key': 'Issue Resolution Competence',
            'value':  candidate['ac_grading'] && candidate['ac_grading']['oppression_faced'] ? candidate['ac_grading']['oppression_faced'] : '-'
          },
          {
            'key': 'Press meets And Programs',
            'value': candidate['ac_grading'] && candidate['ac_grading']['event_management_grading'] ? candidate['ac_grading']['event_management_grading'] : '-'
          },
        ]);
        setPublicAppearance([
          {
            'key': 'Public Appearance Rating',
            'value': candidate['public_grading'] && candidate['public_grading']['public_appearance_grading'] ? candidate['public_grading']['public_appearance_grading'] : '-'
          },
          {
            'key': 'Party Strengthening',
            'value': candidate['ac_grading'] && candidate['ac_grading']['party_adherence_grading'] ? candidate['ac_grading']['party_adherence_grading'] : '-'
          },
          {
            'key': 'Social Appearance',
            'value': candidate['public_grading'] && candidate['public_grading']['social_grading'] ? candidate['public_grading']['social_grading'] : '-'
          },
          {
            'key': 'Cadre Development',
            'value': candidate['public_grading'] && candidate['public_grading']['cadre_grading'] ? candidate['public_grading']['cadre_grading'] : '-'
          },
        ]);
        setPersonality([
          {
            'key': 'Personality Rating',
            'value': candidate['performance_grading'] && candidate['performance_grading']['personality_grading'] ? candidate['performance_grading']['personality_grading'] : '-'
          },
          {
            'key': 'Financial Strength',
            'value': candidate['ac_grading'] && candidate['ac_grading']['financial_commitment'] ? candidate['ac_grading']['financial_commitment'] : '-'
          },
          {
            'key': 'Availability',
            'value': candidate['performance_grading'] && candidate['performance_grading']['attendance'] ? candidate['performance_grading']['attendance'] : '-'
          }
        ]);
        setCandidatePerformance([
          {
            'key': 'Was the financial commitment made honoured',
            'value': candidate['candidate_performance'] && candidate['candidate_performance']['financial_commitment_honoured'] ? candidate['candidate_performance']['financial_commitment_honoured'] : '-'
          },
          {
            'key': 'How well did the candidate manage the election? (Last 1 week effort, Overall Coordination)',
            'value': candidate['candidate_performance'] && candidate['candidate_performance']['last_week_election_management'] ? candidate['candidate_performance']['last_week_election_management'] : '-'
          },
          {
            'key': 'How aggresive was the ACI/candidate against YSRCP over the past 5 year?',
            'value': candidate['candidate_performance'] && candidate['candidate_performance']['aggressive_against_ysrcp'] ? candidate['candidate_performance']['aggressive_against_ysrcp'] : '-'
          },
          {
            'key': 'Did the candidate face YSRCP oppression/aggresion? (Financial loss, cases booked, voilance)',
            'value': candidate['candidate_performance'] && candidate['candidate_performance']['facing_ysrcp_oppression'] ? candidate['candidate_performance']['facing_ysrcp_oppression'] : '-'
          },
          {
            'key': 'Cadre Developement - Joinings, Conflict Resolution, Affliates',
            'value': candidate['candidate_performance'] && candidate['candidate_performance']['cadre_development'] ? candidate['candidate_performance']['cadre_development'] : '-'
          },
          {
            'key': 'Cooperation and adherent to party',
            'value': candidate['candidate_performance'] && candidate['candidate_performance']['cooperation_and_adherent'] ? candidate['candidate_performance']['cooperation_and_adherent'] : '-'
          },
          {
            'key': 'How good was their event management skill? (Party/NPP/NGS Events)',
            'value': candidate['candidate_performance'] && candidate['candidate_performance']['event_management_skill'] ? candidate['candidate_performance']['event_management_skill'] : '-'
          }
        ]);
        setObserverPerformance([
          {
            'key': 'Name of the Observer',
            'value': candidate['candidate_performance'] && candidate['candidate_performance']['name_of_observer'] ? candidate['candidate_performance']['name_of_observer'] : '-'
          },
          {
            'key': 'Was the observer effective?',
            'value': candidate['candidate_performance'] && candidate['candidate_performance']['observer_effectiveness'] ? candidate['candidate_performance']['observer_effectiveness'] : '-'
          },
          {
            'key': 'How well did the observer perform?',
            'value': candidate['candidate_performance'] && candidate['candidate_performance']['observer_performance'] ? candidate['candidate_performance']['observer_performance'] : '-'
          }
        ])
        setMeetingKpiMetrics([
          {
            "key": 'Meeting with founding members',
            'value': candidate['kpi_metrics'] && candidate['kpi_metrics']['meeting_founding_members'] ? candidate['kpi_metrics']['meeting_founding_members'] : '-'
          },
          {
            "key": 'Meeting with influencers',
            'value': candidate['kpi_metrics'] && candidate['kpi_metrics']['meeting_with_influencers'] ? candidate['kpi_metrics']['meeting_with_influencers'] : '-'
          },
          {
            "key": 'Meeting with association members',
            'value': candidate['kpi_metrics'] && candidate['kpi_metrics']['meetings_with_association_members'] ? candidate['kpi_metrics']['meetings_with_association_members'] : '-'
          },
          {
            "key": 'Meeting with womens',
            'value': candidate['kpi_metrics'] && candidate['kpi_metrics']['meeting_with_womens'] ? candidate['kpi_metrics']['meeting_with_womens'] : '-'
          },
          {
            "key": 'No of habitations visited',
            'value': candidate['kpi_metrics'] && candidate['kpi_metrics']['no_of_habitations_visited'] ? candidate['kpi_metrics']['no_of_habitations_visited'] : '-'
          },
          {
            "key": 'Meeting with BC',
            'value': candidate['kpi_metrics'] && candidate['kpi_metrics'] && candidate['kpi_metrics']['meeting_with_bc'] ? candidate['kpi_metrics']['meeting_with_bc'] : '-'
          },
          {
            "key": 'Meeting with dalit galam',
            'value': candidate['kpi_metrics'] && candidate['kpi_metrics']['meeting_with_dalit_galam'] ? candidate['kpi_metrics']['meeting_with_dalit_galam'] : '-'
          }
        ]);
        setSocialMediaMetrics([
              {
                "key": 'Social availability status',
                'value': candidate['kpi_metrics'] && candidate['kpi_metrics']['social_availability_status'] ? candidate['kpi_metrics']['social_availability_status'] : '-'
              },
              {
                "key": 'Social cooperation status',
                'value': candidate['kpi_metrics'] && candidate['kpi_metrics']['social_cooperation_status'] ? candidate['kpi_metrics']['social_cooperation_status'] : '-'
              },
              {
                "key": 'Social Proactiveness',
                'value': candidate['kpi_metrics'] && candidate['kpi_metrics']['social_proactiveness'] ? candidate['kpi_metrics']['social_proactiveness'] : '-'
              },
              {
                "key": 'Social Effectiveness',
                'value':candidate['kpi_metrics'] &&  candidate['kpi_metrics']['social_effectiveness'] ? candidate['kpi_metrics']['social_effectiveness'] : '-'
              },
              {
                "key": 'Social Media Remarks',
                'value': candidate['kpi_metrics'] && candidate['kpi_metrics']['social_media_remarks'] ? candidate['kpi_metrics']['social_media_remarks'] : '-'
              },
              {
                "key": 'War Room Support',
                'value': candidate['kpi_metrics'] && candidate['kpi_metrics']['war_room_support_grading'] ? candidate['kpi_metrics']['war_room_support_grading']  : '-'
              }
            ]
        );
        if (candidate && candidate['educational_qualification']) {
          setEducationQualification(candidate['educational_qualification']);
        }

        setCommittee(candidate['committee']);
        setCaste(candidate['caste']);
        setCasteCategory(candidate['caste_category']);
        setPhoneNumber(candidate['phone_number']);
        setPartyPosition(candidate['party_position']);
        setPostalAwareness(candidate['postal_awareness']);
        setKpiMetrics(candidate['kpi_metrics']);
        setCandidateName(candidate['candidate_name']);
        setConstituency(candidate['constituency']);
        setResourceDistribution(candidate['resource_distribution']);
        setPatAwareness(candidate['pti_actionable']);
        setCandidateImage(candidate['pic_url']);
        setZone(candidate['zone']);
        setBatch(candidate['batch']);
        setAttendance(candidate['attendance_prc'] / 100);
        setCadreDevelopment(candidate['cadre_development']);
        setLabels(candidate['labels']);
        setChartResults(candidate['chart_results']);
        setCandidateProfile(candidate);
        setProgramsConducted(candidate['program_organized']);
        setPressMeetsConducted(candidate['press_meet_organized']);
        setFeedbackByACI(candidate['aci_feedback']);
        setCadreACIFeedback(candidate['cadre_aci_feedback']);
        setTopMandals(candidate['top_performing_mandals']);
      } catch (error) {
        console.error('Error fetching candidates:', error);
      }
    };
    fetchCandidateDetails();
  }, []);
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");

  return (
      <div id="candidate-profile">
        {candidateProfile ?
            <Box pt={{base: "50px", md: "50px", xl: "50px"}}>
              <Grid
                  templateColumns={{
                    base: "0fr",
                    lg: "1.34fr 1fr 1.62fr",
                  }}
                  templateRows={{
                    base: "repeat(3, 1fr)",
                    lg: "1fr",
                  }}
                  gap={{base: "20px", xl: "20px"}}>

                <Banner
                    gridArea='1 / 1 / 2 / 2'
                    banner={banner}
                    avatar={candidateImage}
                    caste={caste}
                    name={candidateName}
                    constituency={constituency}
                    caste_category={casteCategory}
                    education={educationQualification}
                    ph_no={phoneNumber}
                    partyPosition={partyPosition}
                />

                <General
                    gridArea={{base: "2 / 1 / 2 / 2", lg: "1 / 2 / 2 / 5"}}
                    minH='365px'
                    pe='20px'
                    education={grading['overall_grade']}
                    ph_no={attendance}
                    zone={grading['overall_grading_prc']}
                />
              </Grid>

              <Grid mb='20px'
                    templateColumns={{
                      base: "1fr",
                      lg: "repeat(3, 1fr)",
                      "2xl": "1.34fr 1.62fr 1fr",
                    }}
                    templateRows={{
                      base: "repeat(3, 1fr)",
                      lg: "1fr",
                    }}
                    gap={{base: "20px", xl: "20px"}}>
                {performance ?
                    <ComplexTable
                        name={"Performance"}
                        showThead={false}
                        columnsData={genericHeaderLessTable}
                        tableData={performance}
                    /> : null}
                {publicAppearance ?
                    <ComplexTable
                        name={"Public Appearance"}
                        showThead={false}
                        columnsData={genericHeaderLessTable}
                        tableData={publicAppearance}
                    /> : null}
                {personality ?
                    <ComplexTable
                        name={"Personality"}
                        showThead={false}
                        columnsData={genericHeaderLessTable}
                        tableData={personality}
                    /> : null}

              </Grid>
              <div>
                <br/>
                <hr/>
                <Text
                    align={"center"}
                    color={textColorPrimary}
                    fontWeight='bold'
                    fontSize='2xl'
                    mt='10px'
                    mb='4px'>
                  Election Results
                </Text>
                <hr/>
                <br/>
              </div>
              <div id={'election-charts'} >
                <Grid
                    mb='20px'
                    templateColumns={{
                      base: "1fr",
                      lg: "repeat(2, 1fr)",
                      "2xl": "1.34fr 1.62fr 1fr",
                    }}
                    templateRows={{
                      base: "repeat(3, 1fr)",
                      lg: "1fr",
                    }}
                    gap={{base: "20px", xl: "20px"}}>
                  {electionDefCharts ?
                      <PieCard gridArea='1 / 1 / 2 / 2'
                               name={`Election Results -  ${constituency}`} type={"pie"} results={electionDefCharts}
                               labels={['NDA', 'YSRC', 'Others']}/> :
                      null
                  }
                  {chartResults && labels ?
                      <PieCard gridArea={{base: "2 / 1 / 2 / 2", lg: "1 / 2 / 2 / 5"}}
                               name={`Historical Results -  ${constituency}`} type={"bar"} results={chartResults}
                               labels={labels}/> :
                      null
                  }
                </Grid>
              </div>
              {acResults ?
                  <ComplexTable
                      name={"2024 Election Results"}
                      columnsData={electionResults}
                      tableData={acResults}
                  /> : null}
              <br/>

              {results ?
                  <ComplexTable
                      name={"Historical Results Summary"}
                      columnsData={historicalResultsData}
                      tableData={results}
                  /> : null}
              <br/>


              {surveyResults ?
                  <ComplexTable
                      name={"Survey Results"}
                      columnsData={surveyResultsData}
                      tableData={surveyResults}
                  /> : null}
              <br/>
              <div>
                <br/>
                <hr/>
                <Text
                    align={"center"}
                    color={textColorPrimary}
                    fontWeight='bold'
                    fontSize='2xl'
                    mt='10px'
                    mb='4px'>
                  Overall Performance Metrics
                </Text>
                <hr/>
                <Text
                    align={"center"}
                    color={textColorPrimary}
                    fontWeight='bold'
                    fontSize='2xl'
                    mt='10px'
                    mb='4px'>
                  ZC Feedback
                </Text>
                <hr/>
                <br/>
              </div>

              {zonalCoordinatorFeedback ?
                  <ComplexTable
                      name={"Feedback for Candidate"}
                      columnsData={zonalCoordinatorFeedbackHeader}
                      tableData={zonalCoordinatorFeedback}
                  /> : null}
              <br/>
              {zonalObserverFeedback ?
                  <ComplexTable
                      name={"Feedback for Observer"}
                      columnsData={zonalObserverFeedbackHeader}
                      tableData={zonalObserverFeedback}
                  /> : null}
              <br/>
              <Grid
                  mb='20px'
                  templateColumns={{
                    base: "1fr",
                    lg: "repeat(2, 1fr)",
                  }}
                  templateRows={{
                    base: "repeat(2, 1fr)",
                    lg: "1fr",
                  }}
                  gap={{base: "20px", xl: "20px"}}>
                {candidatePerformance ?
                    <ComplexTable
                        gridArea='1 / 1 / 2 / 2'
                        showThead={false}
                        name={"Candidate Performance"}
                        columnsData={genericHeaderLessTable}
                        tableData={candidatePerformance}
                    /> : null}
                {observerPerformance ?
                    <ComplexTable
                        gridArea={{base: "2 / 1 / 2 / 2", lg: "1 / 2 / 2 / 5"}}
                        showThead={false}
                        name={"Observer Performance"}
                        columnsData={genericHeaderLessTable}
                        tableData={observerPerformance}
                    /> : null}
              </Grid>
              <Grid
                  mb='20px'
                  templateColumns={{
                    base: "1fr",
                    lg: "repeat(2, 1fr)",
                  }}
                  templateRows={{
                    base: "repeat(3, 1fr)",
                    lg: "1fr",
                  }}
                  gap={{base: "20px", xl: "20px"}}>
                {pressMeetsConducted ?
                    <ComplexTable
                        gridArea='1 / 1 / 2 / 2'
                        name={"Press Meets"}
                        columnsData={pressMeetsConductedTable}
                        tableData={pressMeetsConducted}
                    /> : null}

                {programsConducted ?
                    <ComplexTable
                        gridArea={{base: "2 / 1 / 2 / 2", lg: "1 / 2 / 2 / 5"}}

                        name={"Programs"}
                        columnsData={programsConductedTable}
                        tableData={programsConducted}
                    /> : null}

              </Grid>
              {kpiMetrics ?
                  <ComplexTable
                      name={"General KPI Metrics"}
                      columnsData={generalKPIMetricsData}
                      tableData={[kpiMetrics]}
                  /> : null}
              <br/>
              <Grid
                  mb='20px'
                  templateColumns={{
                    base: "1fr",
                    lg: "repeat(2, 1fr)",
                  }}
                  templateRows={{
                    base: "repeat(3, 1fr)",
                    lg: "1fr",
                  }}
                  gap={{base: "20px", xl: "20px"}}>
                {meetingKpiMetrics ?
                    <ComplexTable
                        gridArea='1 / 1 / 2 / 2'
                        showThead={false}
                        name={"Meeting KPI Metrics"}
                        columnsData={genericHeaderLessTable}
                        tableData={meetingKpiMetrics}
                    /> : null}
                {socialMediaMetrics ?
                    <ComplexTable
                        gridArea={{base: "2 / 1 / 2 / 2", lg: "1 / 2 / 2 / 5"}}

                        showThead={false}
                        name={"Social Media KPI Metrics"}
                        columnsData={genericHeaderLessTable}
                        tableData={socialMediaMetrics}
                    /> : null}
              </Grid>
              <Grid
                  mb='20px'
                  templateColumns={{
                    base: "1fr",
                    lg: "repeat(2, 1fr)",
                  }}
                  templateRows={{
                    base: "repeat(3, 1fr)",
                    lg: "1fr",
                  }}
                  gap={{base: "20px", xl: "20px"}}>
                {postalAwareness && postalAwareness['form_12_awareness'] ?
                    <ComplexTable
                        gridArea='1 / 1 / 2 / 2'
                        name={"Form 12D Cluster Awareness"}
                        columnsData={form12DData}
                        tableData={postalAwareness['form_12_awareness']}
                    /> : null}
                {postalAwareness && postalAwareness['home_voters_awareness'] ?
                    <ComplexTable
                        gridArea={{base: "2 / 1 / 2 / 2", lg: "1 / 2 / 2 / 5"}}

                        name={"Home Voter Cluster Awareness"}
                        columnsData={homeClusterData}
                        tableData={postalAwareness['home_voters_awareness']}
                    /> : null}
              </Grid>
              {cadreDevelopment ?
                  <ComplexTable
                      name={"Actionable And Cadre Development"}
                      columnsData={cadreDevelopmentDataActionable}
                      tableData={cadreDevelopment}
                  /> : null}
              <br/>
              {resourceDistribution ?
                  <ComplexTable
                      name={"Resource Distribution"}
                      columnsData={resourceDistributionData}
                      tableData={resourceDistribution}
                  /> : null}
              <br/>

              {patAwareness ?
                  <ComplexTable
                      name={"Election Actionable"}
                      columnsData={electionDataActionable}
                      tableData={patAwareness}
                  /> : null}
              <br/>
              <div>
                <br/>
                <hr/>
                <Text
                    align={"center"}
                    color={textColorPrimary}
                    fontWeight='bold'
                    fontSize='2xl'
                    mt='10px'
                    mb='4px'>
                  Feedback Given By ACI
                </Text>

                <hr/>
                <br/>
              </div>
              {feedbackByACI && feedbackByACI.length > 0 ?
                  <ComplexTable
                      name={"Feedback Given By ACI"}
                      columnsData={aciFeedbackTable}
                      tableData={feedbackByACI}
                  /> : null}
              <br/>
              <Grid
                  mb='20px'
                  templateColumns={{
                    base: "1fr",
                    lg: "repeat(2, 1fr)",
                  }}
                  templateRows={{
                    base: "repeat(3, 1fr)",
                    lg: "1fr",
                  }}
                  gap={{base: "20px", xl: "20px"}}>

                {cadreACIFeedback && cadreACIFeedback.length > 0 ?
                    <ComplexTable
                        gridArea='1 / 1 / 2 / 2'
                        name={"Community Leaders"}
                        columnsData={communityLeadersTable}
                        tableData={cadreACIFeedback}
                    /> : null}
                {contributorsACIFeedback && contributorsACIFeedback.length > 0 ?
                    <ComplexTable
                        gridArea={{base: "2 / 1 / 2 / 2", lg: "1 / 2 / 2 / 5"}}

                        name={"Contributors"}
                        columnsData={contributorsTable}
                        tableData={contributorsACIFeedback}
                    /> : null}
              </Grid>
              <Grid
                  mb='20px'
                  templateColumns={{
                    base: "1fr",
                    lg: "repeat(2, 1fr)",
                  }}
                  templateRows={{
                    base: "repeat(3, 1fr)",
                    lg: "1fr",
                  }}
                  gap={{base: "20px", xl: "20px"}}>
                {disruptorsFeedback && disruptorsFeedback.length > 0 ?
                    <ComplexTable
                        gridArea='1 / 1 / 2 / 2'
                        name={"Disruptors"}
                        columnsData={contributorsTable}
                        tableData={contributorsACIFeedback}
                    /> : null}
                {attrocitiesFeedback && attrocitiesFeedback.length > 0 ?
                    <ComplexTable
                        gridArea={{base: "2 / 1 / 2 / 2", lg: "1 / 2 / 2 / 5"}}

                        name={"Atrocities"}
                        columnsData={contributorsTable}
                        tableData={contributorsACIFeedback}
                    /> : null}
              </Grid>
              {summaryCUBSPerformance && summaryCUBSPerformance.length > 0 ?
                  <ComplexTable
                      gridArea={{base: "2 / 1 / 2 / 2", lg: "1 / 2 / 2 / 5"}}

                      name={"Constituency CUBS Performance"}
                      columnsData={cubsPerformance}
                      tableData={summaryCUBSPerformance}
                  /> : null}
              <br/>
              <div>
                <br/>
                <hr/>
                <Text
                    align={"center"}
                    color={textColorPrimary}
                    fontWeight='bold'
                    fontSize='2xl'
                    mt='10px'
                    mb='4px'>
                  FORM 17C Filings
                </Text>
                <hr/>
                <Text
                    align={"center"}
                    color={textColorPrimary}
                    fontWeight='bold'
                    fontSize='2xl'
                    mt='10px'
                    mb='4px'>
                  Campaigns Performance
                </Text>
                <hr/>
                <br/>
                <br/>
              </div>

              <Grid
                  mb='20px'
                  templateColumns={{
                    base: "1fr",
                    lg: "repeat(2, 1fr)",
                  }}
                  templateRows={{
                    base: "repeat(3, 1fr)",
                    lg: "1fr",
                  }}
                  gap={{base: "20px", xl: "20px"}}>
                {campaignStatus && campaignStatus.length > 0 ?
                    <ComplexTable
                        name={"Super 6 Outreach"}
                        columnsData={super6Outreach}
                        tableData={campaignStatus}
                    /> : null}
                {campaignStatus && campaignStatus.length > 0 ?
                    <ComplexTable
                        name={"Public Outreach"}
                        columnsData={poOutreach}
                        tableData={campaignStatus}
                    /> : null}
                {campaignStatus && campaignStatus.length > 0 ?
                    <ComplexTable
                        name={"BSBG Outreach"}
                        columnsData={bsbgOutreach}
                        tableData={campaignStatus}
                    /> : null}
                {campaignStatus && campaignStatus.length > 0 ?
                    <ComplexTable
                        name={"New Voter Registration"}
                        columnsData={newVoterRegistration}
                        tableData={campaignStatus}
                    /> : null}
              </Grid>
            </Box> : <div align={"center"}>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>

              <iframe src="https://lottie.host/embed/c698f656-918b-4a16-8678-f1b7bc4a79f3/n1SzSdcaUG.json"></iframe>
              <br/>

            </div>}
      </div>
  );
}
