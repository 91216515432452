import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
} from "react-icons/md";
import { GrDocumentPerformance } from "react-icons/gr";
import { RiProfileFill } from "react-icons/ri";


// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import MandalResults from "views/admin/mandalResults";
import VillageResults from "views/admin/villageResults";
import BoothResults from "views/admin/boothResults";
import Candidate from "views/admin/candidate";
import RTL from "views/admin/rtl";

// Auth Imports
import SignInCentered from "views/auth/signIn";

const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/default",
    display: true,
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
  },
  {
    name: "Performance",
    layout: "/admin",
    path: "/candidate",
    display: true,
    icon: <Icon as={GrDocumentPerformance} width='20px' height='20px' color='inherit' />,
    component: Candidate,
  },
  {
    name: "Candidate Profile",
    layout: "/admin",
    path: "/profile",
    display: true,
    icon: <Icon as={RiProfileFill} width='20px' height='20px' color='inherit' />,
    component: Profile,
  },
  {
    name: "Mandal Results",
    layout: "/admin",
    path: "/mandal-results",
    display: false,
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: MandalResults,
  },
  {
    name: "Village Results",
    layout: "/admin",
    path: "/village-results",
    display: false,
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: VillageResults,
  },
  {
    name: "Booth Results",
    layout: "/admin",
    path: "/booth-results",
    display: false,
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: BoothResults,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    display: false,
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: SignInCentered,
  },
];

export default routes;
