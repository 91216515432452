// Chakra imports
import { Box, Flex, Text, Select, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import PieChart from "components/charts/PieChart";
import DonutChart from "components/charts/DonutChart";
import BarChart from "components/charts/BarChart";
import {
    barChartHistoricResultOptions,
    barChartHistoricResult, pieChartNoFillOptions,
} from "variables/charts";
import GaugeChart from 'react-gauge-chart'
import { pieChartData, pieChartOptions, donutOptions } from "variables/charts";
import { VSeparator } from "components/separator/Separator";
import {React, useState} from "react";
import Chart from "react-apexcharts";

export default function Conversion(props) {
    const { name, type, percentage , results, labels, type_pie, ...rest } = props;

    // Chakra Color Mode
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const cardColor = useColorModeValue("white", "navy.700");
    const cardShadow = useColorModeValue(
        "0px 18px 40px rgba(112, 144, 176, 0.12)",
        "unset"
    );
    const chartResults = results ? results : [];
    const barLabels = labels ? barChartHistoricResultOptions(labels, textColor): barChartHistoricResultOptions([2019, 2014, 2009], textColor);
    var pieChartGraphs = pieChartOptions(labels, textColor);
    if(type_pie && type_pie == 'other_pie'){
        pieChartGraphs = pieChartNoFillOptions(labels, textColor);
    } else {
        pieChartGraphs = pieChartOptions(labels, textColor);
    }
    const donutGraphs = donutOptions(labels, textColor);
    const [barChatData, setBarChatData] = useState(chartResults);
    const [axes, setAxes] = useState(barLabels);

    return (
        <Card p='20px' align='center' direction='column' w='100%' style={{borderWidth:'1px'}} {...rest}>
            <Flex
                px={{ base: "0px", "2xl": "10px" }}
                justifyContent='space-between'
                alignItems='center'
                w='100%'
                mb='50px'>
                <Text color={textColor} fontSize='2xl' fontWeight='600' mt='4px'>
                    {name}
                </Text>
                {/*<Select
              fontSize='sm'
              variant='subtle'
              defaultValue='monthly'
              width='unset'
              fontWeight='700'>
            <option value='daily'>Daily</option>
            <option value='monthly'>Monthly</option>
            <option value='yearly'>Yearly</option>
          </Select>*/}
            </Flex>
            {
                type === "guage" ? (
                    <GaugeChart id="gauge-chart3"
                                nrOfLevels={10}
                                colors={["#d94532","#FF5F6D", "#FFC371", "#b5e8d5", "#3f8c38"]}
                                arcWidth={0.3}
                                textColor={textColor}
                                percent={percentage}
                    />
                ) : null
            }
            {
                type === "bar" ? (
                    <BarChart
                        type={"bar"}
                        chartData={barChatData}
                        chartOptions={barLabels}
                    />
                ) : null
            }
            {
                type === "pie" ? (
                    <PieChart
                        chartData={barChatData}
                        chartOptions={pieChartGraphs}
                    />
                ):null
            }
            {
                type === "donut" ? (
                    <DonutChart
                        chartData={barChatData}
                        chartOptions={donutGraphs}
                    />
                ):null
            }
            {/*<Card
            bg={cardColor}
            flexDirection='row'
            boxShadow={cardShadow}
            w='100%'
            p='15px'
            px='20px'
            mt='15px'
            mx='auto'>
          <Flex direction='column' py='5px'>
            <Flex align='center'>
              <Box h='8px' w='8px' bg='brand.500' borderRadius='50%' me='4px' />
              <Text
                  fontSize='xs'
                  color='secondaryGray.600'
                  fontWeight='700'
                  mb='5px'>
                Yes
              </Text>
            </Flex>
            <Text fontSize='lg' color={textColor} fontWeight='700'>
              63%
            </Text>
          </Flex>
          <VSeparator mx={{ base: "60px", xl: "60px", "2xl": "60px" }} />
          <Flex direction='column' py='5px' me='10px'>
            <Flex align='center'>
              <Box h='8px' w='8px' bg='#6AD2FF' borderRadius='50%' me='4px' />
              <Text
                  fontSize='xs'
                  color='secondaryGray.600'
                  fontWeight='700'
                  mb='5px'>
                No
              </Text>
            </Flex>
            <Text fontSize='lg' color={textColor} fontWeight='700'>
              25%
            </Text>
          </Flex>
        </Card>*/}
        </Card>
    );
}
