// Chakra imports
import { Avatar, Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";

export default function Banner(props) {
    const { banner, avatar, name, constituency, caste, caste_category,  education, ph_no, partyPosition} = props;
    // Chakra Color Mode
    const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
    const textColorSecondary = "gray.400";
    const borderColor = useColorModeValue(
        "white !important",
        "#111C44 !important"
    );
    return (
        <Card mb={{ base: "0px", lg: "20px" }} style={{marginBottom:"15px", borderWidth:'1px'}} align='center'>
            <Box
                bg={`url(${banner})`}
                bgSize='cover'
                borderRadius='16px'
                h='164px'
                w='100%'
            />
            <Avatar
                mx='auto'
                src={avatar}
                h='140px'
                w='140px'
                mt='-43px'
                border='4px solid'
                borderColor={borderColor}
            />
            <Text color={textColorPrimary} fontWeight='bold' fontSize='xl' mt='10px'>
                {name}
            </Text>
            <Text color={textColorPrimary} fontSize='md' fontWeight={"700"}>
                Position: {partyPosition}
            </Text>
            <Text color={textColorPrimary} fontSize='md' fontWeight={"700"}>
                Education: {education}
            </Text>
            <Text color={textColorPrimary} fontSize='md' fontWeight={"700"}>
                Constituency: {constituency}
            </Text>
            <Text color={textColorPrimary} fontSize='md' fontWeight={"700"}>
                Ph No: {ph_no}
            </Text>

            <Text color={textColorPrimary} fontSize='md' fontWeight={"700"}>
                Caste : {caste} ({caste_category})
            </Text>

        </Card>
    );
}
