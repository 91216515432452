// Chakra Imports
import {
	Avatar,
	Button,
	Flex,
	Icon,
	Image,
	Link,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	useColorModeValue,
	ColorModeScript
} from '@chakra-ui/react';
// Custom Components
import { ItemContent } from 'components/menu/ItemContent';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import { ConstituencyMenu } from 'components/menu/ConstituencyMenu';
import PropTypes from 'prop-types';
import {React, useState} from 'react';
// Assets
import navImage from 'assets/img/layout/Navbar.png';
import { FaPrint } from 'react-icons/fa';
import { FaEthereum } from 'react-icons/fa';
import routes from 'routes.js';
import { ThemeEditor } from './ThemeEditor';
import axios from "axios";
import FixedPlugin from "../fixedPlugin/FixedPlugin";


export default function HeaderLinks(props) {
	const { secondary } = props;
	// Chakra Color Mode
	const navbarIcon = useColorModeValue('gray.400', 'white');
	let menuBg = useColorModeValue('rgba(244, 247, 254)', 'rgba(11,20,55)');

	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const textColorBrand = useColorModeValue('brand.700', 'brand.400');
	const ethColor = useColorModeValue('gray.700', 'white');
	const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
	const ethBg = useColorModeValue('secondaryGray.300', 'navy.900');
	const ethBox = useColorModeValue('white', 'navy.800');
	const shadow = useColorModeValue(
		'14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
		'14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
	);
	const borderButton = useColorModeValue('secondaryGray.500', 'whiteAlpha.200');

	const onButtonClick = () => {
		window.location.href = '/'
	}

	const printClick = () =>{
		const content = document.getElementById('candidate-profile'), clone = content.cloneNode(true); // true means clone all childNodes and all event handlers
		clone.id = "cloned-profile";
		let pri
		let uniqueIframeId = 'print-doc'
		let electionCharts = clone.querySelector("[id='election-charts']")
		electionCharts.parentNode.removeChild(electionCharts);

		if (document.getElementById(uniqueIframeId)) {
			pri = document.getElementById(uniqueIframeId).contentWindow
		} else {
			const iframe = document.createElement('iframe')
			iframe.setAttribute('title', uniqueIframeId)
			iframe.setAttribute('id', uniqueIframeId)
			//iframe.setAttribute('style', 'height: 0px; width: 0px; position: absolute;')
			document.body.appendChild(iframe)
			pri = iframe.contentWindow
		}
		pri.document.open()
		pri.document.write(clone.innerHTML)
		pri.document.close()
		pri.focus()
		pri.print()
	}

	return (
		<Flex
			w={{sm: '100%', md: 'auto'}}
			alignItems="center"
			flexDirection="row"
			bg={menuBg}
			flexWrap={secondary ? {base: 'wrap', md: 'nowrap'} : 'unset'}
			p="10px"
			borderRadius="100px"
		>
			{ window.location.href.includes("profile") ?
				<SearchBar mb={secondary ? {base: '10px', md: 'unset'} : 'unset'} me="10px" borderRadius="30px"/> :
				window.location.href.includes("results") || window.location.href.includes("default") ?
					<ConstituencyMenu mb={secondary ? {base: '10px', md: 'unset'} : 'unset'} me="10px"/> : null}

			<Button align="center" display="flex" bg={menuBg} h="40px" w="40px" borderRadius="50px" justify='center me="10px"'>
				<Icon as={FaPrint} color={navbarIcon} w="18px" h="18px"/>
			</Button>
			<FixedPlugin />
			{ !window.location.href.includes("results") ?
				<Menu>
					<MenuButton p="0px">
						<Avatar
							_hover={{cursor: 'pointer'}}
							color="white"
							name="Rigas Tech"
							bg="#11047A"
							size="sm"
							w="40px"
							h="40px"
						/>
					</MenuButton>
					<MenuList boxShadow={shadow} p="0px" mt="10px" borderRadius="20px" bg={menuBg} border="none">
						<Flex w="100%" mb="0px">
							<Text
								ps="20px"
								pt="16px"
								pb="10px"
								w="100%"
								borderBottom="1px solid"
								borderColor={borderColor}
								fontSize="sm"
								fontWeight="700"
								color={textColor}>
								👋&nbsp; Hey, Admin
							</Text>
						</Flex>
						<Flex flexDirection="column" p="10px">
							{/*<MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} borderRadius="8px" px="14px">
							<Text fontSize="sm">Profile Settings</Text>
						</MenuItem>
						<MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} borderRadius="8px" px="14px">
							<Text fontSize="sm">Newsletter Settings</Text>
						</MenuItem>*/}
							<MenuItem
								_hover={{bg: 'none'}}
								_focus={{bg: 'none'}}
								color="red.400"
								borderRadius="8px"
								px="14px">
								<Text fontSize="sm" onClick={onButtonClick}>Log out</Text>
							</MenuItem>
						</Flex>
					</MenuList>
				</Menu>: null}
		</Flex>
	);
}

HeaderLinks.propTypes = {
	variant: PropTypes.string,
	fixed: PropTypes.bool,
	secondary: PropTypes.bool,
	onOpen: PropTypes.func
};
