import {React, useEffect, useState} from 'react';
import {
    IconButton,
    Input,
    InputGroup,
    InputLeftElement,
    useColorModeValue,
} from "@chakra-ui/react";
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import {getMembers} from "../../../api/api-helpers";



export function SearchBar(props) {
    // Pass the computed styles into the `__css` prop
    const { variant, background, children, placeholder, borderRadius, searchItems, ...rest } =
        props;
    // Chakra Color Mode
    const searchIconColor = useColorModeValue("gray.700", "white");
    const inputBg = useColorModeValue("secondaryGray.300", "navy.900");
    const inputText = useColorModeValue("gray.700", "gray.100");
    const [items, setItems] = useState();
    useEffect(() => {
        const fetchCandidates = async () => {
            try {
                const items = [];
                const response = await getMembers();
                const candidates = response.data.candidates;
                for (let cand of candidates){
                    items.push({ id: cand.member_id, name: cand.candidate_name })
                    items.push({ id: cand.member_id, name: cand.member_id })
                }
                setItems(items);
            } catch (error) {
                console.error('Error fetching candidates:', error);
            }
        };

        fetchCandidates();
    }, []); // Empty dependency arra

    const handleOnSearch = (string, results) => {
        // Triggered when the user types in the search input
        console.log(string, results);
    };

    const handleOnHover = (item) => {
        // Triggered when the user hovers over an item in the suggestions list
        console.log('Item hovered:', item);
    };

    const handleOnChange = (item) => {
        window.location.href = `#/admin/profile?id=${item.id}&name=${item.name}`
        window.location.reload();
    }


    return (
        <InputGroup w={{base: "100%", md: "200px"}} {...rest}>
            <InputLeftElement
                children={
                    <IconButton
                        bg='inherit'
                        borderRadius='inherit'
                        _hover='none'
                        _active={{
                            bg: "inherit",
                            transform: "none",
                            borderColor: "transparent",
                        }}
                        _focus={{
                            boxShadow: "none",
                        }}
                    ></IconButton>
                }
            />
            {/*<Input
        variant='search'
        fontSize='sm'
        bg={background ? background : inputBg}
        color={inputText}
        fontWeight='500'
        _placeholder={{ color: "gray.400", fontSize: "14px" }}
        borderRadius={borderRadius ? borderRadius : "30px"}
        placeholder={placeholder ? placeholder : "Search..."}
      />*/}
            <div style={{width: '100%'}}>
                <ReactSearchAutocomplete
                    fontSize='sm'
                    bg={background ? background : inputBg}
                    color={inputText}
                    fontWeight='500'
                    _placeholder={{ color: "gray.400", fontSize: "14px" }}
                    borderRadius={borderRadius ? borderRadius : "30px"}
                    placeholder={placeholder ? placeholder : "Search..."}
                    items={items}
                    onSearch={handleOnSearch}
                    onHover={handleOnHover}
                    onSelect={handleOnChange}
                />
            </div>

        </InputGroup>
    );
}
