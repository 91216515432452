// Chakra Imports
import {Button, Icon, useColorMode, useColorModeValue} from "@chakra-ui/react";
// Custom Icons
import { IoMdMoon, IoMdSunny } from "react-icons/io";
import React from "react";

export default function FixedPlugin(props) {
    const { ...rest } = props;
    const { colorMode, toggleColorMode } = useColorMode();
    const navbarIcon = useColorModeValue('gray.400', 'white');
	let menuBg = useColorModeValue('rgba(244, 247, 254)', 'rgba(11,20,55)');
    function toggleColor(){
        toggleColorMode(true);
        window.location.reload();
    }


    return (
        <Button
            {...rest}
            align="center"
            display="flex"
            h="40px" w="40px"
            borderRadius="50px"
            me="10px"
            bg={menuBg}
            onClick={toggleColor}
            justify='center'>
            <Icon
                h='18px'
                w='18px'
                color={navbarIcon}
                as={colorMode === "light" ? IoMdMoon : IoMdSunny}
            />
        </Button>
    );
}