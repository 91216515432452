// Chakra imports
import {
  Box,
  Grid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import React, {useEffect, useState} from "react";
import ComplexTable from "views/admin/default/components/ComplexTable";
import PieCard from "views/admin/default/components/PieCard";
import {
  assemblyElectionResultsData,
  assemblyHistoricalElectionResultsData,
  historicTopPerformingBoothsResultsData, historicTopPerformingMandalsResultsData,
  historicTopPerformingVillagesResultsData,
  leaderFeedbackData,
  topPerformingBoothsResultsData,
  topPerformingMandalsResultsData,
  topPerformingVillagesResultsData,
} from "views/admin/default/variables/columnsData";
import {getACDetailsById, getParameterByName} from "../../../api/api-helpers";
import {Banner} from "../profile/components/Storage";

export default function UserReports() {
  // Chakra Color Mode

  let acNo = getParameterByName('ac_no');
  acNo = acNo ? acNo : "1";
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const [acNO, setAcNO] = useState();
  const [assembly, setAssembly] = useState();
  const [constituency, setConstituency] = useState();
  const [electionResults, setElectionResults] = useState();
  const [historicalResults, setHistoricalResults] = useState();
  const [historicalDefCharts, setHistoricalDefCharts] = useState();
  const [voteDistribution, setVoteDistribution] = useState();
  const [mandalLevelVotes, setMandalLevelVotes] = useState();
  const [mandalLabels, setMandalLabels] = useState();
  const [topMandals, setTopMandals] = useState();
  const [electionDefCharts, setElectionDefCharts] = useState();
  const [labels, setLabels] = useState();
  const [topVillages, setTopVillages] = useState();
  const [topBooths, setTopBooth] = useState();
  const [historicTopMandals, setHistoricTopMandals] = useState();
  const [historicTopBooths, setHistoricTopBooth] = useState();
  const [historicTopVillages, setHistoricTopVillages] = useState();
  const [leadersFeedbackSummary, setLeadersFeedbackSummary] = useState([]);
  useEffect(() => {
    const fetchCandidateDetails = async () => {
      try {
        const response = await getACDetailsById(acNo);
        let assemblyDetail = response.data;
        setAcNO(acNo);
        let acResults  = assemblyDetail['election_results'];
        let historicResults = assemblyDetail['historic_results'];
        let voteDistribution = assemblyDetail['vote_distribution'];
        let leadersFeedbackSummary = assemblyDetail['leaders_feedback_summary'];
        if (acResults && acResults.length > 0){
          let electionCharts = [];
          acResults = acResults[0];
          electionCharts.push(acResults['nda_votes']);
          electionCharts.push(acResults['ysrc_votes']);
          electionCharts.push(acResults['total_polled'] - acResults['nda_votes'] - acResults['ysrc_votes']);
          setElectionDefCharts(electionCharts);
        }
        setElectionResults(assemblyDetail['election_results']);
        setHistoricalResults(assemblyDetail['historic_results']);
        setConstituency(assemblyDetail['assembly_name']);
        setLeadersFeedbackSummary(leadersFeedbackSummary);
        if (voteDistribution && voteDistribution.length > 0) {
          let mandalLabels = [], tdp_data = [] , ysrc_data = [], others_data= [], total_votes = [];
          for(let i of voteDistribution){
            mandalLabels.push(i['mandal_or_town']);
            tdp_data.push(i['mandal_tdp_prc']);
            ysrc_data.push(i['mandal_ycp_prc']);
            let num = 100 - i['mandal_tdp_prc'] - i['mandal_ycp_prc'];
            total_votes.push(i['mandal_total_polled']);
            others_data.push(Math.round(num * 100) / 100);
            setMandalLevelVotes(total_votes);
          }
          setVoteDistribution([
            {'name': 'TDP Vote', 'data': tdp_data},
            {'name': 'YSRC Vote', 'data': ysrc_data},
            {'name': 'Others Vote', 'data': others_data}
          ]);
          setMandalLabels(mandalLabels);
        }
        if (historicResults && historicResults.length > 0) {
          let yearLabels = [], tdp_data = [] , ysrc_data = [], others_data= [];
          for(let i of historicResults){
            yearLabels.push(i['Year'])
            tdp_data.push(i['tdp_prc'])
            ysrc_data.push(i['ycp_prc']);
            let num = 100 - i['tdp_prc'] - i['ycp_prc'];
            others_data.push(Math.round(num * 100) / 100);
          }
          setHistoricalDefCharts([
            {'name': 'TDP Vote', 'data': tdp_data},
            {'name': 'YSRC Vote', 'data': ysrc_data},
            {'name': 'Others Vote', 'data': others_data}
          ]);
          setLabels(yearLabels);
        }
        setTopMandals(assemblyDetail['top_mandals']);
        setTopVillages(assemblyDetail['top_villages']);
        setTopBooth(assemblyDetail['top_booths']);
        setHistoricTopMandals(assemblyDetail['historic_top_mandals']);
        setHistoricTopVillages(assemblyDetail['historic_top_villages']);
        setHistoricTopBooth(assemblyDetail['historic_top_booths']);
        setAssembly(assemblyDetail);
      } catch (error) {
        console.error('Error fetching candidates:', error);
      }
    };
    fetchCandidateDetails();
  }, []);

  return (
      <div id="candidate-profile">
        {assembly ?
            <Box pt={{base: "50px", md: "50px", xl: "50px"}}>
              <Grid
                  templateColumns={{
                    base: "0fr",
                    lg: "1.34fr 1fr 1.62fr",
                  }}
                  templateRows={{
                    base: "repeat(3, 1fr)",
                    lg: "1fr",
                  }}
                  gap={{base: "20px", xl: "20px"}}>
                <Banner  gridArea='1 / 1 / 2 / 2' name={assembly['assembly_name']}
                         district={assembly['district']}
                         parliament={assembly['parliament']}
                         grade={assembly['grading']}
                         gain={assembly['assembly_gain']}
                         gain_prc={assembly['assembly_gain_prc']}
                />
                {electionDefCharts ?
                    <PieCard gridArea={{base: "2 / 1 / 2 / 2", lg: "1 / 2 / 2 / 5"}}
                             name={`Election Results -  ${constituency}`} type={"donut"} results={electionDefCharts}
                             labels={['NDA', 'YSRC', 'Others']}/> :
                    null
                }
              </Grid>
              <br/>
              {electionResults ?
                  <div>
                    <ComplexTable
                        name={`Election Results - ${constituency}`}
                        acNo={acNo}
                        columnsData={assemblyElectionResultsData}
                        tableData={electionResults}
                    />
                    <br/>
                  </div>: null}
              <div id={'election-charts'}>
                <Grid
                    mb='20px'
                    templateColumns={{
                      base: "1fr",
                      lg: "repeat(2, 1fr)",
                      "2xl": "1.34fr 1.62fr 1fr",
                    }}
                    templateRows={{
                      base: "repeat(3, 1fr)",
                      lg: "1fr",
                    }}
                    gap={{base: "20px", xl: "20px"}}>

                  {voteDistribution ?
                      <PieCard gridArea={{base: "1 / 1 / 2 / 2"}}
                               name={`Mandal Election Results -  ${constituency}`} type={"bar"}
                               results={voteDistribution}
                               labels={mandalLabels}
                      /> :
                      null
                  }
                  {mandalLevelVotes ?
                      <PieCard gridArea={{base: "2 / 1 / 2 / 2", lg: "1 / 2 / 2 / 5"}}
                               name={`Mandal Vote Distibution -  ${constituency}`} type={"pie"}
                               results={mandalLevelVotes}
                               labels={mandalLabels} type_pie={"other_pie"}
                      /> :
                      null
                  }
                </Grid>
                <Grid
                    mb='20px'
                    templateColumns={{
                      base: "1fr",
                      lg: "repeat(2, 1fr)",
                      "2xl": "1.34fr 1.62fr 1fr",
                    }}
                    templateRows={{
                      base: "repeat(3, 1fr)",
                      lg: "1fr",
                    }}
                    gap={{base: "20px", xl: "20px"}}>

                  {historicalDefCharts ?
                      <PieCard gridArea={{base: "1 / 1 / 2 / 5"}}
                               name={`Historical Results -  ${constituency}`} type={"bar"} results={historicalDefCharts}
                               labels={labels}
                      /> :
                      null
                  }
                </Grid>
                {historicalResults ?
                    <div><ComplexTable
                        name={"Historical Results Summary"}
                        columnsData={assemblyHistoricalElectionResultsData}
                        tableData={historicalResults}
                    /> <br/></div> : null}

                <div>
                  <br/>
                  <hr/>
                  <Text
                      align={"center"}
                      color={textColorPrimary}
                      fontWeight='bold'
                      fontSize='2xl'
                      mt='10px'
                      mb='4px'>
                    AC Performance
                  </Text>
                  <hr/>
                  <Text
                      align={"center"}
                      color={textColorPrimary}
                      fontWeight='bold'
                      fontSize='2xl'
                      mt='10px'
                      mb='4px'>
                    2024 Performance
                  </Text>
                  <hr/>
                  <br/>
                </div>
                <Grid
                    mb='20px'
                    templateColumns={{
                      base: "1fr",
                      lg: "repeat(3, 1fr)",
                    }}
                    templateRows={{
                      base: "repeat(3, 1fr)",
                      lg: "1fr",
                    }}
                    gap={{base: "20px", xl: "20px"}}>

                  {topMandals ?
                      <ComplexTable
                          name={"Mandals"}
                          columnsData={topPerformingMandalsResultsData}
                          acNo={acNO}
                          showLink={true}
                          tableData={topMandals}
                      /> : null}
                  {topVillages ?
                      <ComplexTable
                          name={"Villages"}
                          columnsData={topPerformingVillagesResultsData}
                          acNo={acNO}
                          showLink={true}
                          tableData={topVillages}
                      /> : null}
                  {topBooths ?
                      <ComplexTable
                          name={"Booths"}
                          columnsData={topPerformingBoothsResultsData}
                          acNo={acNO}
                          showLink={true}
                          tableData={topBooths}
                      /> : null}
                </Grid>
                <div>
                  <br/>
                  <hr/>
                  <Text
                      align={"center"}
                      color={textColorPrimary}
                      fontWeight='bold'
                      fontSize='2xl'
                      mt='10px'
                      mb='4px'>
                    Historical Performance
                  </Text>
                  <hr/>
                  <br/>
                </div>
                <Grid
                    mb='20px'
                    templateColumns={{
                      base: "1fr",
                      lg: "repeat(3, 1fr)",
                    }}
                    templateRows={{
                      base: "repeat(3, 1fr)",
                      lg: "1fr",
                    }}
                    gap={{base: "20px", xl: "20px"}}>

                  {historicTopMandals ?
                      <ComplexTable
                          name={"Mandals"}
                          columnsData={historicTopPerformingMandalsResultsData}
                          acNo={acNO}
                          showLink={true}
                          tableData={historicTopMandals}
                      /> : null}
                  {historicTopVillages ?
                      <ComplexTable
                          name={"Villages"}
                          columnsData={historicTopPerformingVillagesResultsData}
                          acNo={acNO}
                          showLink={true}
                          tableData={historicTopVillages}
                      /> : null}
                  {historicTopBooths ?
                      <ComplexTable
                          name={"Booths"}
                          columnsData={historicTopPerformingBoothsResultsData}
                          acNo={acNO}
                          tableData={historicTopBooths}
                      /> : null}
                </Grid>
                <br/>
                <div>
                  <br/>
                  <hr/>
                  <Text
                      align={"center"}
                      color={textColorPrimary}
                      fontWeight='bold'
                      fontSize='2xl'
                      mt='10px'
                      mb='4px'>
                    Top Performing Leader's
                  </Text>
                  <hr/>
                  <br/>
                </div>
              </div>
              <Grid
                  mb='20px'
                  templateColumns={{
                    base: "1fr",
                    lg: "repeat(2, 1fr)",
                  }}
                  templateRows={{
                    base: "repeat(2, 1fr)",
                    lg: "1fr",
                  }}
                  gap={{base: "20px", xl: "20px"}}>
                { leadersFeedbackSummary && leadersFeedbackSummary.length > 0 ?
                    leadersFeedbackSummary.map(e =>
                        <ComplexTable
                            name={`Top Performing Leaders - ${e['committee_level']}`}
                            columnsData={leaderFeedbackData}
                            acNo={acNO}
                            tableData={e['leaders']}
                        />
                    ) :  null
                }
              </Grid>

            </Box> : <div align={"center"}>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <iframe src="https://lottie.host/embed/c698f656-918b-4a16-8678-f1b7bc4a79f3/n1SzSdcaUG.json"></iframe>
              <br/>

            </div>}
      </div>
  );
}
