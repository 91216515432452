import {
    ChakraProvider,
    ModalCloseButton,
    Flex,
    Table,
    Icon,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Box,
    Tr,
    Grid, GridItem,
    useColorModeValue, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure
} from "@chakra-ui/react";
//import InfiniteScroll from "react-infinite-scroll-component";
import React, {useEffect, useMemo, useState} from "react";
import {
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable,
} from "react-table";

// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import { ImNewTab } from "react-icons/im";
import { HiOutlineBookOpen } from "react-icons/hi";

// Assets
import {MdCheckCircle, MdCancel, MdOutlineError, MdBarChart} from "react-icons/md";
import { TbReportAnalytics } from "react-icons/tb";
import {leaderFeedbackData, leaderFeedbackPropData} from "../variables/columnsData";
import DonutChart from "../../../../components/charts/DonutChart";
import PieChart from "../../../../components/charts/PieChart";
import {progressDonutOptions} from "../../../../variables/charts";
import Chart from "react-apexcharts";

const surveyResultsHeaders = ["CATI Candidate", "CATI Alliance", "CATI Latest", "IVR-3", "IVR-2", "CAPI Latest"];
const performanceTable = ["Candidate Performance", "Observer Performance", "Performance", "Public Appearance", "Personality"];
const kpiPerformanceHeaders = ["Office Branding", "Press Conference", "Worker's Meetings", "Avg # Of Attendance", "Youth Meetings Conducted", "Avg # Youth Attendance", "Road Shows Conducted", "Avg Road Shows Crowd"];
const cubsPerformanceHeaders = ["5-Excellent", "4-Good", "3-Average", "2-Poor", "1-No Performance"];
const voteHeaders = ["TDP Vote %", "YSRC Vote %", "INC Vote %", "NDA Vote %"];
const majorityHeaders = ["Majority %", "MAJORITY", "TDP Gain %", "TDP Gain"];
const linkTableHeaders = ["Villages", "Mandals", "Booths"];
const linkColumnHeaders = ["Village", "Mandal or Town", "Booth Id"];

function OpenModal(props){
    const { isOpen, onClose, name, feedbackData} = props;
    const textColor = useColorModeValue("white", "white");

    return (
        <Modal isOpen={isOpen} onClose={onClose} scrollBehavior={"inside"} size={"6xl"}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Leader Feedback</ModalHeader>
                <ModalBody>
                    {feedbackData ? feedbackData['feedback_list'].map((feed)=>
                            <div>
                                <Text color={textColor} fontSize='md' fontWeight='700'>
                                    Average Rank: {feedbackData['average_rank']}
                                </Text>
                                <Text color={textColor} fontSize='md' fontWeight='700'>
                                    Overall Score: {feedbackData['overall_score']}
                                </Text>
                                <br/>
                                <ColumnsTable
                                    name="Leader Feedback"
                                    showName={false}
                                    columnsData={leaderFeedbackPropData}
                                    tableData={feed}
                                />
                                <hr/>
                                <br/>
                            </div>)
                        : null}

                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="brand" mr={3} onClick={onClose}>
                        <Text fontSize='md' color={textColor} fontWeight='700'>
                            Close
                        </Text>
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
function normalizeValues(dictionary) {
    // Get the lengths of the arrays
    const lengths = Object.values(dictionary).map(arr => arr.length);

    // Check if all lengths are the same
    const maxLength = Math.max(...lengths);
    const allEqual = lengths.every(length => length === maxLength);

    if (allEqual) {
        return dictionary; // All arrays have the same length
    }

    // Normalize arrays to have the same length
    for (let key in dictionary) {
        let arr = dictionary[key];
        if (arr.length < maxLength) {
            // Extend the array with '-' to match the maximum length
            while (arr.length < maxLength) {
                arr.push('-');
            }
        }
    }

    return dictionary;
}

const PerformanceReportModal = ({ isOpen, onClose, performanceReport }) => {
    const textColor = useColorModeValue("secondaryGray.900", "white");

    const getColorByGrade = (grade) => {
        switch (grade) {
            case 'A': return '#91d050';
            case 'B': return '#b8dcab';
            case 'C': return '#ffcc26';
            case 'D': return '#fefe99';
            case 'E': return '#ff5050';
            default: return '';
        }
    };

    if (!performanceReport) return null;

    performanceReport['metrics'] = {
        columns: ['Name', 'Weight (%)', 'Score'],
        data: [
            ['Election Result', 20, performanceReport.election_grading],
            ['Field Work', 30, performanceReport.field_work],
            ['False Police Cases', 10, performanceReport.false_policy_cases],
            ['Loyalty', 5, performanceReport.party_loyality],
            ['Fight Against Ycp Govt', 10, performanceReport.flight_against_ycp_govt],
            ['Financial Impact Due To Ycp', 10, performanceReport.financial_impact_due_to_ycp],
            ['Financial Support To Party', 10, performanceReport.financial_support_for_party],
            ['Seniority', 5, performanceReport.party_seniority],
            ['Overall Score', 100, performanceReport.overall_grading],
        ],
    };

    performanceReport['PerformanceRating'] = {
        columns: ['Name', 'Type', 'Weight (%)', 'Score'],
        data: [
            ['Performance Rating', 'Performance', 10, performanceReport['grading'] && performanceReport['grading']['candidate_grading_prc'] ? performanceReport['grading']['candidate_grading_prc'] : '-'],
            ['Issue Resolution Competence', 'Performance', 10, performanceReport['ac_grading'] && performanceReport['ac_grading']['oppression_faced'] ? performanceReport['ac_grading']['oppression_faced'] : '-'],
            ['Press meets And Programs', 'Performance', 10, performanceReport['ac_grading'] && performanceReport['ac_grading']['event_management_grading'] ? performanceReport['ac_grading']['event_management_grading'] : '-'],
            ['Public Appearance Rating', 'Public Appearance', 10, performanceReport['public_grading'] && performanceReport['public_grading']['public_appearance_grading'] ? performanceReport['public_grading']['public_appearance_grading'] : '-'],
            ['Party Strengthening', 'Public Appearance', 10, performanceReport['ac_grading'] && performanceReport['ac_grading']['party_adherence_grading'] ? performanceReport['ac_grading']['party_adherence_grading'] : '-'],
            ['Social Appearance', 'Public Appearance', 10, performanceReport['public_grading'] && performanceReport['public_grading']['social_grading'] ? performanceReport['public_grading']['social_grading'] : '-'],
            ['Cadre Development', 'Public Appearance', 10, performanceReport['public_grading'] && performanceReport['public_grading']['cadre_grading'] ? performanceReport['public_grading']['cadre_grading'] : '-'],
            ['Personality Rating', 'Personality', 10, performanceReport['performance_grading'] && performanceReport['performance_grading']['personality_grading'] ? performanceReport['performance_grading']['personality_grading'] : '-'],
            ['Financial Strength', 'Personality', 10, performanceReport['ac_grading'] && performanceReport['ac_grading']['financial_commitment'] ? performanceReport['ac_grading']['financial_commitment'] : '-'],
            ['Availability', 'Personality', 10, performanceReport['performance_grading'] && performanceReport['performance_grading']['attendance'] ? performanceReport['performance_grading']['attendance'] : '-'],
            ['Overall Score', '-', 100, performanceReport['grading'] && performanceReport['grading']['overall_grading_prc'] ? performanceReport['grading']['overall_grading_prc'] : '-'],
        ],
    };

    let questionNames = {};
    let data = [];
    let feedbackDetails = performanceReport.feedback_details;

    for (let r of feedbackDetails) {
        for (let q of r) {
            let answer = q['answer'] ? q['answer'] :  '-'
            if (!questionNames[q['question_name']]) {
                questionNames[q['question_name']] = [answer];
            } else {
                questionNames[q['question_name']].push(answer);
            }
        }
        questionNames = normalizeValues(questionNames);
    }

    for (let [key, value] of Object.entries(questionNames)) {
        data.push([key, ...value]);
    }

    let columns = ["Question"];
    if (feedbackDetails.length > 1) {
        feedbackDetails.forEach((rows, idx) => {
            columns.push(`${performanceReport.team_type} - ${idx + 1}`);
        });
    } else {
        columns.push(performanceReport.team_type);
    }

    performanceReport['table'] = {
        columns: columns,
        data: data
    };
    console.log(performanceReport.table.data)

    const chartOptions = {
        chart: {
            type: 'radialBar',
        },
        plotOptions: {
            radialBar: {
                hollow: {
                    size: '50%',
                },
                track: {
                    background: '#ff5050',
                },
                dataLabels: {
                    name: {
                        show: false,
                    },
                    value: {
                        fontSize: '30px',
                        show: true,
                        color: textColor,
                        formatter: () => performanceReport.overall_grading
                    },
                },
            },
        },
        colors: ["#91d050"],
        labels: ['Overall Score'],
    };

    const handlePrint = () => {
        const printContent = document.getElementById('print-content');
        const newWindow = window.open('', '', 'width=800,height=600');
        newWindow.document.write('<html><head><title>Performance Report - ' + performanceReport.leader_name + '</title>');
        newWindow.document.write('<style>');
        newWindow.document.write('body { font-family: Arial, sans-serif; margin: 0; padding: 0; }');
        newWindow.document.write('h2 { font-family: Arial; text-align: center }');
        newWindow.document.write('.container { max-width: 800px; margin: 0 auto; padding: 20px; }');
        newWindow.document.write('.table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }');
        newWindow.document.write('.table th, .table td { padding: 8px; border: 1px solid #ddd; text-align: left; }');
        newWindow.document.write('.table th { background-color: #f4f4f4; }');
        newWindow.document.write('.table tr.total-row td { font-weight: bold; }');
        newWindow.document.write('line {display: none}')
        newWindow.document.write('.grid-container { display: grid; grid-template-columns: 1fr 1fr 1fr; gap: 10px; }');
        newWindow.document.write('.score p {width: 80%; text-align:center}');
        newWindow.document.write('@media print {');
        newWindow.document.write('.grid-container { display: grid; grid-template-columns: 1fr 1fr 1fr; gap: 10px; }');
        newWindow.document.write('.score p {width: 80%; text-align:center}');
        newWindow.document.write('.table { page-break-inside: auto; }');
        newWindow.document.write('.table tr { page-break-inside: auto; page-break-after: auto; }');
        newWindow.document.write('}');
        newWindow.document.write('</style>');
        newWindow.document.write('</head><body>');
        newWindow.document.write('<div class="container">' + printContent.innerHTML + '</div>');
        newWindow.document.write('</body></html>');
        newWindow.document.close();
        newWindow.print();
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="5xl">
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalBody id="print-content">
                    <br/>
                    <Box className="report" maxWidth="800px" margin="0 auto" padding="5px">
                        <hr />
                        <Text as="h2" fontSize="md" fontWeight="700" textAlign="center">Performance Report</Text>
                        <hr />
                        <Grid templateColumns="1fr 1fr 1fr" gap="20px" marginBottom="20px" className="grid-container">
                            <GridItem>
                                <Flex direction="column">
                                    <GridItem>
                                        <Text><strong>Name:</strong> {performanceReport.leader_name  || '-'}</Text>
                                    </GridItem>
                                    <GridItem>
                                        <Text><strong>Assembly:</strong> {performanceReport.constituency_name  || '-'}</Text>
                                    </GridItem>
                                    <GridItem>
                                        <Text><strong>Voter Id:</strong> {performanceReport.voter_id  || '-'}</Text>
                                    </GridItem>
                                    <GridItem>
                                        <Text><strong>Committee Level:</strong> {performanceReport.committee_level || '-'}</Text>
                                    </GridItem>
                                    <GridItem>
                                        <Text><strong>Overall Grade:</strong> <span style={{ color: getColorByGrade(performanceReport.overall_grade) }}>{performanceReport.overall_grade}</span></Text>
                                    </GridItem>
                                </Flex>
                            </GridItem>
                            <GridItem>
                                <Flex direction="column">
                                    <GridItem>
                                        <Text><strong>Caste:</strong> {performanceReport.caste_name  || '-'}</Text>
                                    </GridItem>
                                    <GridItem>
                                        <Text><strong>Mobile:</strong> {performanceReport.mobile_no  || '-'}</Text>
                                    </GridItem>
                                    <GridItem>
                                        <Text><strong>Member Id:</strong> {performanceReport.membership_id  || '-'}</Text>
                                    </GridItem>
                                    <GridItem>
                                        <Text><strong>Role:</strong> {performanceReport.role || '-'}</Text>
                                    </GridItem>
                                    <GridItem>
                                        <Text><strong>No of Feedback Received:</strong> {performanceReport.feedback_details.length}</Text>
                                    </GridItem>
                                </Flex>
                            </GridItem>
                            <GridItem>
                                <Flex className="score">
                                    <Box>
                                        <Chart options={chartOptions} series={[performanceReport.overall_grading]} type="radialBar" width="200" />
                                        <Text textAlign="center"><strong>Overall Score</strong></Text>
                                    </Box>
                                </Flex>
                            </GridItem>
                        </Grid>
                        <hr />
                        <Text as="h2" fontSize="md" fontWeight="700" textAlign="center">Individual Performance</Text>
                        <hr />
                        <Flex>
                            <Table className="table">
                                <Thead>
                                    <Tr>
                                        {performanceReport.metrics.columns.map((col, index) => (
                                            <Th key={index}>{col}</Th>
                                        ))}
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {performanceReport.metrics.data.map((row, rowIndex) => (
                                        <Tr key={rowIndex}  className={rowIndex === performanceReport.PerformanceRating.data.length - 1 ? 'total-row' : ''}>
                                            {row.map((cell, cellIndex) => (
                                                <Td key={cellIndex}>{cell}</Td>
                                            ))}
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </Flex>
                        <hr />
                        <Text as="h2" fontSize="md" fontWeight="700" textAlign="center">Individual & AC Performance (SCC & PC Data)</Text>
                        <hr />
                        <Flex>
                            <Table className="table">
                                <Thead>
                                    <Tr>
                                        {performanceReport.PerformanceRating.columns.map((col, index) => (
                                            <Th key={index}>{col}</Th>
                                        ))}
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {performanceReport.PerformanceRating.data.map((row, rowIndex) => (
                                        <Tr key={rowIndex} className={rowIndex === performanceReport.PerformanceRating.data.length - 1 ? 'total-row' : ''}>
                                            {row.map((cell, cellIndex) => (
                                                <Td key={cellIndex}>{cell}</Td>
                                            ))}
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </Flex>
                        <hr />
                        <Text as="h2" fontSize="md" fontWeight="700" textAlign="center">Member Feedback</Text>
                        <hr />
                        <Flex>
                            <Table className="table">
                                <Thead>
                                    <Tr>
                                        {performanceReport.table.columns.map((col, index) => (
                                            <Th key={index}>{col}</Th>
                                        ))}
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {performanceReport.table.data.map((row, rowIndex) => (
                                        <Tr key={rowIndex}>
                                            {row.map((cell, cellIndex) => (
                                                <Td key={cellIndex}>{cell ? cell : '-'}</Td>
                                            ))}
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </Flex>
                    </Box>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={handlePrint} colorScheme="brand" style={{"marginRight": "10px"}}>
                        <Text color={"white"} fontSize='md' fontWeight='700'>
                            Print
                        </Text>
                    </Button>
                    <Button onClick={onClose} colorScheme="brand">
                        <Text color={"white"} fontSize='md' fontWeight='700'>
                            Close
                        </Text>
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default function ColumnsTable(props) {
    const {name, columnsData, acNo, showLink, tableData, showThead, showName, maxHieght} = props;
    const columns = useMemo(() => columnsData, [columnsData]);
    const data = useMemo(() => tableData, [tableData]);
    const headVisible = useMemo(() => showThead, [showThead]);
    const {isOpen, onOpen, onClose} = useDisclosure()
    const {isOpen: isPerformanceOpen, onOpen: onPerformanceOpen, onClose: onPerformanceClose} = useDisclosure()
    const [feedback, setFeedback] = useState();
    const [performanceReport, setPerformanceReport] = useState();

    const openUpdateModal = (event) => {
        setFeedback(event.original);
        onOpen(); //useState for Modal
    };

    const openPerformanceUpdateModal = (event) => {
        setPerformanceReport(event.original);
        onPerformanceOpen(); //useState for Modal
    };

    const tableInstance = useTable(
        {
            columns,
            data
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        initialState,
    } = tableInstance;
    initialState.pageSize =  1000;

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
    const tableColor = useColorModeValue("secondaryGray.900", "gray.500");
    const iconColor = useColorModeValue("brand.200", "white");
    const [show, setShow] = React.useState(false);
    const handleToggle = () => setShow(!show);
    const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
    const bgHover = useColorModeValue(
        { bg: "secondaryGray.400" },
        { bg: "whiteAlpha.50" }
    );
    const bgFocus = useColorModeValue(
        { bg: "secondaryGray.300" },
        { bg: "whiteAlpha.100" }
    );
    return (
        <Card
            direction='column'
            w='100%'
            px='0px'
            overflowX={{ sm: "scroll", lg: "hidden" }} style={{borderWidth:'1px', borderColor:borderColor}}>
            <OpenModal acNo={acNo} name={name} isOpen={isOpen} feedbackData={feedback} tableName={name} onClose={onClose}/>
            <PerformanceReportModal acNo={acNo} name={name} isOpen={isPerformanceOpen} performanceReport={performanceReport} tableName={name} onClose={onPerformanceClose}/>
            { showName ?
                <Flex px='25px' justify='space-between' mb='10px' align='center' padding="10px">
                    <Text
                        color={textColor}
                        fontSize='2xl'
                        fontWeight='700'
                        lineHeight='100%'>
                        {name} { (name.includes('Actionable') || name.includes('Community Leaders') ||
                        name.includes('Contributors') || name.includes('Disruptors') || name.includes('Atrocities')) && data && data.length > 0 ? '('+ data.length +')': ''}
                    </Text>
                    {/*  <Menu />*/}
                </Flex>
                : null
            }
            <Box overflowY="auto" maxHeight={maxHieght ? maxHieght : "300px"}>
                <Table {...getTableProps()} variant='striped' color={{tableColor}} mb='24px'>
                    <Thead position="sticky">
                        {headerGroups && headerGroups.length > 0 ? headerGroups.map((headerGroup, index) => (
                            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                                {headerGroup.headers.map((column, index) => (
                                    headVisible ?
                                        (<Th
                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                            pe='10px'
                                            key={index}
                                            borderColor={borderColor}>
                                            <Flex
                                                justify='space-between'
                                                align='center'
                                                fontSize={{ sm: "10px", lg: "12px" }}
                                                color='gray.400'>
                                                {column.render("Header")}
                                            </Flex>
                                        </Th>):null
                                ))}
                            </Tr>

                        )) : null}
                    </Thead>

                    <Tbody {...getTableBodyProps()}>
                        {page.map((row, index) => {
                            prepareRow(row);
                            return (
                                <Tr {...row.getRowProps()} key={index}>
                                    {row.cells.map((cell, index) => {
                                        let data = "";
                                        if (cell.column.Header === "NAME") {
                                            data = (
                                                <Text color={textColor} fontSize='md' fontWeight='700'>
                                                    {cell.value}
                                                </Text>
                                            );
                                        } else if(linkTableHeaders.includes(name) && linkColumnHeaders.includes(cell.column.Header)){
                                            let color = textColor;
                                            let url  = '', mandal_name = row.original.Mandal_Or_Town, village_name = row.original.Village;
                                            if (name === 'Villages'){
                                                url = `#/admin/village-results?ac_no=${acNo}&mandal_name=${mandal_name}&village_name=${cell.value}`
                                            } else if (name === 'Booths'){
                                                url = `#/admin/booth-results?ac_no=${acNo}&mandal_name=${mandal_name}&village_name=${village_name}&booth_id=${cell.value}`
                                            } else if (name === 'Mandals'){
                                                url = `#/admin/mandal-results?ac_no=${acNo}&mandal_name=${cell.value}`
                                            }
                                            data = (<a onClick={()=> window.open(url, "_blank")}>
                                                <Text color={color} fontSize='md' fontWeight='700'>
                                                    {cell.value} {showLink ? <Icon as={ImNewTab} color={iconColor} w='14px' h='14px' /> : null}
                                                </Text>
                                            </a>);
                                        } else if(voteHeaders.includes(cell.column.Header) && name !== 'Historical Results Summary'){
                                            let color = textColor;
                                            if( cell.value >= 50){
                                                color = "green.500"
                                            } else {
                                                color = "red.500"
                                            }
                                            data = (
                                                <Text color={color} fontSize='md' fontWeight='700'>
                                                    {cell.value}
                                                </Text>
                                            );
                                        } else if(majorityHeaders.includes(cell.column.Header)){
                                            let color = textColor;
                                            if( cell.value > 0){
                                                color = "green.500"
                                            } else if (cell.value === 0){
                                                color = "orange.500"
                                            } else {
                                                color = "red.500"
                                            }
                                            data = (
                                                <Text color={color} fontSize='md' fontWeight='700'>
                                                    {cell.value}
                                                </Text>
                                            );
                                        } else if (cell.column.Header === 'Grade') {
                                            let color = textColor;
                                            if( cell.value === 'A'){
                                                color = "green.500"
                                            } else if (cell.value === 'B'){
                                                color = "green.300"
                                            } else if (cell.value === 'C'){
                                                color = "yellow.400"
                                            } else if (cell.value === 'D'){
                                                color = "red.200"
                                            } else if (cell.value === 'E'){
                                                color = "red.500"
                                            }
                                            data = (
                                                <Text color={color} fontSize='md' fontWeight='700'>
                                                    {cell.value}
                                                </Text>
                                            );

                                        } else if (cell.column.Header === "Won") {
                                            data = (
                                                <Flex align='center'>
                                                    <Icon
                                                        w='24px'
                                                        h='24px'
                                                        me='5px'
                                                        color={
                                                            cell.value === 'Won'
                                                                ? "green.500"
                                                                : cell.value === 'Lost'
                                                                    ? "red.500"
                                                                    : cell.value === 'Tie'
                                                                        ? "orange.500"
                                                                        : null
                                                        }
                                                        as={
                                                            cell.value === 'Won'
                                                                ? MdCheckCircle
                                                                : cell.value === 'Lost'
                                                                    ? MdCancel
                                                                    : cell.value === 'Tie'
                                                                        ? MdOutlineError
                                                                        : null
                                                        }
                                                    />
                                                </Flex>
                                            );
                                        } else if (name === "Social Media KPI Metrics" || cell.column.Header === "Total %" || cell.column.Header === "Overall Score"){
                                            let color = textColor;
                                            if( cell.value >= 60){
                                                color = "green.500"
                                            } else if (cell.value > 20 && cell.value <60){
                                                color = "yellow.200"
                                            } else if (cell.value <= 20){
                                                color = "red.500"
                                            }
                                            data = (
                                                <Text color={color} fontSize='md' fontWeight='700' onClick={handleToggle}>
                                                    {cell.value}
                                                </Text>
                                            );
                                        } else if (name === "Social Media KPI Metrics" && cell.column.Header === "value") {
                                            let color = textColor;
                                            if( cell.value === 'Yes' || cell.value === 'Fully Cooperative' ||
                                                cell.value === 'Highly Effective (Closes Actionables)' || cell.value === 'Highly Proactive & Committed'
                                                || cell.value === 'Supportive'){
                                                color = "green.500"
                                            } else if (cell.value === 'Somewhat Effective' || cell.value === 'Somewhat Proactive'
                                                || cell.value === 'Less Supportive' || cell.value === 'No'){
                                                color = "yellow.200"
                                            } else if (cell.value === 'Moderately Supportive'){
                                                color = "green.200"
                                            } else if (cell.value === 'Not Cooperative' || cell.value  === "Ineffective (Doesn't Close Actionables)"
                                                || cell.value === 'Not Serious' || cell.value === 'Not Supportive at all'){
                                                color = "red.500"
                                            }
                                            data = (
                                                <Text color={color} fontSize='md' fontWeight='700' >
                                                    {cell.value}
                                                </Text>
                                            );
                                        } else if (cubsPerformanceHeaders.includes(cell.column.Header)) {
                                            let color = textColor;
                                            if( cell.column.Header === '5-Excellent'){
                                                color = "green.500"
                                            } else if (cell.column.Header === '4-Good'){
                                                color = "green.300"
                                            } else if (cell.column.Header === '3-Average'){
                                                color = "yellow.200"
                                            } else if (cell.column.Header === '2-Poor'){
                                                color = "red.200"
                                            } else if (cell.column.Header === '1-No Performance'){
                                                color = "red.500"
                                            }
                                            data = (
                                                <Text color={color} fontSize='md' fontWeight='700'>
                                                    {cell.value}
                                                </Text>
                                            );

                                        } else if (cell.column.Header === 'Average Rank') {
                                            let color = textColor;
                                            if( cell.value < 2){
                                                color = "green.500"
                                            } else if (cell.value < 3){
                                                color = "green.300"
                                            } else if (cell.value < 4){
                                                color = "yellow.200"
                                            } else if (cell.value > 4){
                                                color = "red.500"
                                            }
                                            data = (
                                                <Text color={color} fontSize='md' fontWeight='700'>
                                                    {cell.value}
                                                </Text>
                                            );

                                        } else if (kpiPerformanceHeaders.includes(cell.column.Header)) {
                                            let color = textColor;
                                            if( cell.value === 'Completed' || cell.value === 'Conducted' || cell.value >= 3000 ){
                                                color = "green.500"
                                            } else if (cell.value === 'Not Initiated' || cell.value === 'Not Conducted'){
                                                color = "red.500"
                                            } else if (cell.value === 'Started' || cell.value <= 3000){
                                                color = "yellow.200"
                                            }
                                            if( cell.column.Header === 'Avg # Of Attendance' || cell.column.Header === 'Avg # Youth Attendance'){
                                                let value = cell.value && cell.value.length > 0 ? cell.value.replace(',', '') : '';
                                                if (value >= 3000){
                                                    color = "green.500"
                                                } else {
                                                    color = "yellow.200"
                                                }
                                            }
                                            if( cell.column.Header === 'Avg Road Shows Crowd'){
                                                let value = cell.value && cell.value.length > 0 ? cell.value.replace(',', '') : '';
                                                if (value >= 30000){
                                                    color = "green.500"
                                                } else if (value> 10000 && value < 30000) {
                                                    color = "yellow.200"
                                                } else {
                                                    color = "red.500"
                                                }
                                            }

                                            data = (
                                                <Text color={color} fontSize='md' fontWeight='700'>
                                                    {cell.value}
                                                </Text>
                                            );

                                        } else if (performanceTable.includes(name) && cell.column.Header === 'value') {
                                            let color = textColor;
                                            if( cell.value === 'Yes' || cell.value >= 4){
                                                color = "green.500"
                                            } else if (cell.value === 'No' || cell.value <= 2){
                                                color = "red.500"
                                            } else if (cell.value > 2 && cell.value < 4 ){
                                                color = "yellow.200"
                                            } else if( cell.value >= 60){
                                                color = "green.500"
                                            } else if (cell.value > 20 && cell.value <60){
                                                color = "yellow.200"
                                            } else if (cell.value <= 20){
                                                color = "red.500"
                                            }
                                            data = (
                                                <Text color={color} fontSize='md' fontWeight='700'>
                                                    {cell.value}
                                                </Text>
                                            );
                                        } else if (surveyResultsHeaders.includes(cell.column.Header)) {
                                            let color = "green.300";
                                            if( cell.value === 'VERY GOOD'){
                                                color = "green.500"
                                            } else if (cell.value === 'KEEN'){
                                                color = "yellow.200"
                                            } else if (cell.value === 'N/A'){
                                                color = "grey.200"
                                            }
                                            data = (
                                                <Text color={color} fontSize='md' fontWeight='700'>
                                                    {cell.value ? cell.value: '-'}
                                                </Text>
                                            );
                                        } else if (cell.column.Header === 'Leader Name' && name !== 'Candidates'){
                                            data = (
                                                <Text color={textColor} fontSize='md' fontWeight='700' onClick={e => openUpdateModal(row)}>
                                                    <Icon as={HiOutlineBookOpen} color={iconColor} w='15px' h='15px' /> { cell.value ? cell.value.replace('.', '. '): '-'}
                                                </Text>
                                            );
                                        } else if (cell.column.Header === 'Leader Name' && name === 'Candidates'){
                                            data = (
                                                <Text color={textColor} fontSize='md' fontWeight='700' onClick={e => openPerformanceUpdateModal(row)}>
                                                    <Icon as={TbReportAnalytics} color={iconColor} w='15px' h='15px' /> { cell.value ? cell.value.replace('.', '. '): '-'}
                                                </Text>
                                            );
                                        } else {
                                            data = (
                                                <Text color={textColor} fontSize='md' fontWeight='700'>
                                                    {cell.value ? cell.value: '-'}
                                                </Text>
                                            );
                                        }
                                        return (
                                            <Td
                                                {...cell.getCellProps()}
                                                key={index}
                                                fontSize={{ sm: "14px" }}
                                                maxH='30px !important'
                                                py='8px'
                                                minW={{ sm: "150px", md: "200px", lg: "auto" }}
                                                borderColor='transparent'>
                                                {data}
                                            </Td>
                                        );
                                    })}
                                </Tr>
                            );
                        })}
                    </Tbody>
                </Table>
            </Box>
        </Card>
    );
}

ColumnsTable.defaultProps = {
    showLink: false,
    showThead: true,
    showName: true
};
