import HistoryItem from "../../marketplace/components/HistoryItem";
import {React} from "react";

export const columnsDataCheck = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const historicalResultsData = [
  {
    Header: "Constituency",
    accessor: "constituency",
  },
  {
    Header: "Year",
    accessor: "year",
  },
  {
    Header: "TDP Vote %",
    accessor: "TDP Vote",
  },
  {
    Header: "YSRC Vote %",
    accessor: "YSRC Vote",
  },
  {
    Header: "INC Vote %",
    accessor: "INC Vote",
  },
  {
    Header: "Majority %",
    accessor: "majority",
  },
  {
    Header: "Won",
    accessor: "won",
  }
];


export const leaderFeedbackPropData = [
  {
    Header: "Question",
    accessor: "question_name",
  },
  {
    Header: "Answer",
    accessor: "answer",
  }
];

export const leaderFeedbackData = [
  {
    Header: "Leader Name",
    accessor: "leader_name",
  },
  {
    Header: "Overall Score",
    accessor: "overall_score",
  },
  {
    Header: "Average Rank",
    accessor: "average_rank",
  }
];

export const leaderFeedbackVillageData = [
  {
    Header: "Booth Id",
    accessor: "booth_id",
  },
  {
    Header: "Leader Name",
    accessor: "leader_name",
  },
  {
    Header: "Designation",
    accessor: "designation",
  },
  {
    Header: "No of People Rated",
    accessor: "no_of_people_rated",
  },
  {
    Header: "Approval Rating",
    accessor: "approval_rating",
  }
];

export const historicTopPerformingMandalsResultsData = [
  {
    Header: "Mandal or Town",
    accessor: "Mandal_Or_Town",
  },
  {
    Header: "Year",
    accessor: "Year",
  },
  {
    Header: "TDP Vote %",
    accessor: "mandal_tdp_prc",
  },
  {
    Header: "YSRC Vote %",
    accessor: "mandal_ycp_prc",
  },
  {
    Header: "TDP Gain %",
    accessor: "tdp_gain_prc",
  },
  {
    Header: "Grade",
    accessor: "grading",
  }
];

export const historicTopPerformingVillagesResultsData = [
  {
    Header: "Village",
    accessor: "Village",
  },
  {
    Header: "Year",
    accessor: "Year",
  },
  {
    Header: "TDP Vote %",
    accessor: "village_tdp_prc",
  },
  {
    Header: "YSRC Vote %",
    accessor: "village_ycp_prc",
  },
  {
    Header: "TDP Gain %",
    accessor: "tdp_gain_prc",
  },
  {
    Header: "Grade",
    accessor: "grading",
  }
];

export const historicTopPerformingBoothsResultsData = [
  {
    Header: "Booth Id",
    accessor: "Booth_ID",
  },
  {
    Header: "Year",
    accessor: "Year",
  },
  {
    Header: "TDP Vote %",
    accessor: "tdp_prc",
  },
  {
    Header: "YSRC Vote %",
    accessor: "ycp_prc",
  },
  {
    Header: "TDP Gain %",
    accessor: "tdp_gain_prc",
  },
  {
    Header: "Grade",
    accessor: "grading",
  }
];

export const topPerformingMandalsResultsData = [
  {
    Header: "Mandal or Town",
    accessor: "Mandal_Or_Town",
  },
  {
    Header: "TDP Vote %",
    accessor: "mandal_tdp_prc",
  },
  {
    Header: "YSRC Vote %",
    accessor: "mandal_ycp_prc",
  },
  {
    Header: "TDP Gain %",
    accessor: "tdp_gain_prc",
  },
  {
    Header: "Grade",
    accessor: "grading",
  }
];

export const topPerformingVillagesResultsData = [
  {
    Header: "Village",
    accessor: "Village",
  },
  {
    Header: "TDP Vote %",
    accessor: "village_tdp_prc",
  },
  {
    Header: "YSRC Vote %",
    accessor: "village_ycp_prc",
  },
  {
    Header: "TDP Gain %",
    accessor: "tdp_gain_prc",
  },
  {
    Header: "Grade",
    accessor: "grading",
  }
];

export const topPerformingBoothsResultsData = [
  {
    Header: "Booth Id",
    accessor: "Booth_ID",
  },
  {
    Header: "TDP Vote %",
    accessor: "tdp_prc",
  },
  {
    Header: "YSRC Vote %",
    accessor: "ycp_prc",
  },
  {
    Header: "TDP Gain %",
    accessor: "tdp_gain_prc",
  },
  {
    Header: "Grade",
    accessor: "grading",
  }
];

export const mandalElectionResultsData = [
  {
    Header: "TDP Vote",
    accessor: "mandal_tdp_votes",
  },
  {
    Header: "TDP Vote %",
    accessor: "mandal_tdp_prc",
  },
  {
    Header: "YSRC Vote",
    accessor: "mandal_ysrc_votes",
  },
  {
    Header: "YSRC Vote %",
    accessor: "mandal_ycp_prc",
  },
  {
    Header: "Others Vote",
    accessor: "mandal_others_votes",
  },
  {
    Header: "TDP Gain",
    accessor: "mandal_tdp_gain",
  },
  {
    Header: "TDP Gain %",
    accessor: "tdp_gain_prc",
  },
  {
    Header: "Grade",
    accessor: "grading",
  }
];

export const historicMandalElectionResultsData = [
  {
    Header: "Year",
    accessor: "year",
  },
  {
    Header: "TDP Vote",
    accessor: "mandal_tdp_votes",
  },
  {
    Header: "TDP Vote %",
    accessor: "mandal_tdp_prc",
  },
  {
    Header: "YSRC Vote",
    accessor: "mandal_ysrc_votes",
  },
  {
    Header: "YSRC Vote %",
    accessor: "mandal_ycp_prc",
  },
  {
    Header: "Others Vote",
    accessor: "mandal_others_votes",
  },
  {
    Header: "TDP Gain",
    accessor: "mandal_tdp_gain",
  },
  {
    Header: "TDP Gain %",
    accessor: "tdp_gain_prc",
  },
  {
    Header: "Grade",
    accessor: "grading",
  }
];

export const assemblyHistoricalElectionResultsData = [
  {
    Header: "Year",
    accessor: "Year",
  },
  {
    Header: "TDP Vote %",
    accessor: "tdp_prc",
  },
  {
    Header: "TDP Gain",
    accessor: "tdp_gain_prc",
  },
  {
    Header: "Grade",
    accessor: "grading",
  }
];

export const assemblyElectionResultsData = [
  {
    Header: "NDA Vote",
    accessor: "nda_votes",
  },
  {
    Header: "NDA Vote %",
    accessor: "nda_prc",
  },
  {
    Header: "YSRC Vote",
    accessor: "ysrc_votes",
  },
  {
    Header: "YSRC Vote %",
    accessor: "ycp_prc",
  },
  {
    Header: "Others Vote",
    accessor: "others_votes",
  },
  {
    Header: "TDP Gain",
    accessor: "tdp_gain_votes",
  },
  {
    Header: "TDP Gain %",
    accessor: "tdp_gain_prc",
  },
  {
    Header: "Grade",
    accessor: "grading",
  }
];

export const villageElectionResultsData = [
  {
    Header: "TDP Vote",
    accessor: "village_tdp_votes",
  },
  {
    Header: "TDP Vote %",
    accessor: "village_tdp_prc",
  },
  {
    Header: "YSRC Vote",
    accessor: "village_ysrc_votes",
  },
  {
    Header: "YSRC Vote %",
    accessor: "village_ycp_prc",
  },
  {
    Header: "Others Vote",
    accessor: "village_others_votes",
  },
  {
    Header: "TDP Gain",
    accessor: "village_tdp_gain",
  },
  {
    Header: "TDP Gain %",
    accessor: "tdp_gain_prc",
  },
  {
    Header: "Grade",
    accessor: "grading",
  }
];

export const historicVillageElectionResultsData = [
  {
    Header: "Year",
    accessor: "year",
  },
  {
    Header: "TDP Vote",
    accessor: "village_tdp_votes",
  },
  {
    Header: "TDP Vote %",
    accessor: "village_tdp_prc",
  },
  {
    Header: "YSRC Vote",
    accessor: "village_ysrc_votes",
  },
  {
    Header: "YSRC Vote %",
    accessor: "village_ycp_prc",
  },
  {
    Header: "Others Vote",
    accessor: "village_others_votes",
  },
  {
    Header: "TDP Gain",
    accessor: "village_tdp_gain",
  },
  {
    Header: "TDP Gain %",
    accessor: "tdp_gain_prc",
  },
  {
    Header: "Grade",
    accessor: "grading",
  }
];

export const boothElectionResultsData = [
  {
    Header: "TDP Vote",
    accessor: "tdp_votes",
  },
  {
    Header: "TDP Vote %",
    accessor: "tdp_prc",
  },
  {
    Header: "YSRC Vote",
    accessor: "ysrc_votes",
  },
  {
    Header: "YSRC Vote %",
    accessor: "ycp_prc",
  },
  {
    Header: "Others Vote",
    accessor: "others_votes",
  },
  {
    Header: "TDP Gain",
    accessor: "tdp_gain",
  },
  {
    Header: "TDP Gain %",
    accessor: "tdp_gain_prc",
  },
  {
    Header: "Grade",
    accessor: "grading",
  }
];

export const surveyResultsData = [
  {
    Header: "CATI Candidate",
    accessor: "cati_candidate",
  },
  {
    Header: "CATI Alliance",
    accessor: "cati_alliance",
  },
  {
    Header: "CATI Latest",
    accessor: "CATI-2",
  },
  {
    Header: "IVR-3",
    accessor: "ivr_3",
  },
  {
    Header: "IVR-2",
    accessor: "ivr_2",
  },
  {
    Header: "CAPI Latest",
    accessor: "capi_latest",
  }
];

export const generalKPIMetricsData = [
  {
    Header: "Office Branding",
    accessor: "office_branding",
  },
  {
    Header: "Press Conference",
    accessor: "press_conference",
  },
  {
    Header: "Worker's Meetings",
    accessor: "workers_meetings",
  },
  {
    Header: "Avg # Of Attendance",
    accessor: "attendees_in_workers_meetings",
  },
  {
    Header: "Youth Meetings Conducted",
    accessor: "youth_meetings_conducted",
  },
  {
    Header: "Avg # Youth Attendance",
    accessor: "avg_meetings_crowd",
  },
  {
    Header: "Road Shows Conducted",
    accessor: "road_shows_conducted",
  },
  {
    Header: "Avg Road Shows Crowd",
    accessor: "avg_roadshow_crowd",
  }

];

export const form12DData = [
  {
    Header: "AC Name",
    accessor: "ac_name",
  },
  {
    Header: "Yes",
    accessor: "Yes",
  },
  {
    Header: "No",
    accessor: "No",
  }

];

export const homeClusterData = [
  {
    Header: "AC Name",
    accessor: "ac_name",
  },
  {
    Header: "Yes",
    accessor: "Yes",
  },
  {
    Header: "No",
    accessor: "No",
  }
];


export const resourceDistributionData = [
  {
    Header: "AC Name",
    accessor: "ac_name",
  },
  {
    Header: "Party",
    accessor: "party",
  },
  {
    Header: "Started Booths",
    accessor: "started_booths",
  },
  {
    Header: "Booth Completed",
    accessor: "Started Booths-PRC",
  },
  {
    Header: "1000",
    accessor: "range_1000",
  },
  {
    Header: "1000-2000",
    accessor: "range_1000_2000",
  },
  {
    Header: "2000-3000",
    accessor: "range_2000_3000",
  },
  {
    Header: "3000-4000",
    accessor: "range_3000_4000",
  },
  {
    Header: "4000-5000",
    accessor: "range_4000_5000",
  }
];
export const electionDataActionable = [
  {
    Header: "Constituency",
    accessor: "constituency",
  },
  {
    Header: "Booth No",
    accessor: "booth_no",
  },
  {
    Header: "Actionable",
    accessor: "actionable",
  }
];

export const cadreDevelopmentDataActionable = [
  {
    Header: "Type Of Actionables",
    accessor: "type_of_actionables",
  },
  {
    Header: "Actionables",
    accessor: "actionables",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "POC For Actionable",
    accessor: "poc_for_actionable",
  },
  {
    Header: "Remarks",
    accessor: "remarks",
  },
  {
    Header: "Date",
    accessor: "date_of_actionables",
  }
];

export const columnsDataComplex = [
  {
    Header: "constituency",
    accessor: "constituency",
  },
  {
    Header: "year",
    accessor: "year",
  },
  {
    Header: "TDP Vote PRC",
    accessor: "TDP Vote",
  },
  {
    Header: "YSRC Vote PRC",
    accessor: "YSRC Vote",
  },
  {
    Header: "INC Vote PRC",
    accessor: "INC Vote",
  },
  {
    Header: "Majority",
    accessor: "majority",
  }
];

export const genericHeaderLessTable = [
  {
    Header: "key",
    accessor: "key",
    hideHeader : true

  },
  {
    Header: "value",
    accessor: "value",
    hideHeader : true

  }
];
export const pressMeetsConductedTable = [
  {
    Header: "Total Press Meets",
    accessor: "total_press_meets",
  },
  {
    Header: "Press Meets Conducted",
    accessor: "conducted_press_meets",
  },
  {
    Header: "ACI Participation",
    accessor: "press_meets_aci_participation",
  },
  {
    Header: "ACI Participation %",
    accessor: "press_meets_aci_part_prc",
  }
];

export const programsConductedTable = [
  {
    Header: "Total Programs",
    accessor: "total_programs",
  },
  {
    Header: "Programs Conducted",
    accessor: "programs_conducted",
  },
  {
    Header: "ACI Participation",
    accessor: "prog_aci_participation",
  },
  {
    Header: "ACI Participation %",
    accessor: "programs_aci_part_prc",
  }
];

export const aciFeedbackTable = [
  {
    Header: "Questions",
    accessor: "question",
  },
  {
    Header: "Feedback",
    accessor: "answer",
  },
  {
    Header: "Comments",
    accessor: "comments",
  }
];

export const communityLeadersDataTable = [
  {
    Header: "Leader Name",
    accessor: "leader_name",
  },
  {
    Header: "Constituency",
    accessor: "constituency_name",
  },

  {
    Header: "Caste",
    accessor: "caste_name",
  },
  {
    Header: "TDP Committee Level",
    accessor: "tdp_committee_level",
  },
  {
    Header: "Overall Rating",
    accessor: "overall_grading",
  }
];

export const communityLeadersTable = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Mandal/Town/Village",
    accessor: "mandal_town_village",
  },
  {
    Header: "Rating",
    accessor: "rating",
  },
  {
    Header: "Comments",
    accessor: "comments",
  },
  {
    Header: "MID",
    accessor: "mid",
  }
];

export const contributorsTable = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Party Position",
    accessor: "party_position",
  },
  {
    Header: "Caste",
    accessor: "caste",
  },
  {
    Header: "Comments",
    accessor: "comments",
  }
];

export const disruptorsTable = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Party",
    accessor: "party",
  },
  {
    Header: "Comments",
    accessor: "comments",
  }
];

export const attrocitiesTable = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Party Position",
    accessor: "party_position",
  },
  {
    Header: "When and Where",
    accessor: "when_and_where",
  },
  {
    Header: "Comments",
    accessor: "comments",
  }
];

export const cubsPerformance = [
  {
    Header: "5-Excellent",
    accessor: "5_excellent",
  },
  {
    Header: "4-Good",
    accessor: "4_good",
  },
  {
    Header: "3-Average",
    accessor: "3_average",
  },
  {
    Header: "2-Poor",
    accessor: "2_poor",
  },
  {
    Header: "1-No Performance",
    accessor: "1_no_performance",
  },
  {
    Header: "Total",
    accessor: "count",
  }
];

export const super6Outreach = [
  {
    Header: "Total",
    accessor: "s6_total_houses",
  },
  {
    Header: "Visited",
    accessor: "S6_visited_houses",
  },
  {
    Header: "Total %",
    accessor: "S6_visited_houses_prc",
  },
];

export const poOutreach = [
  {
    Header: "Total",
    accessor: "po_total_houses",
  },
  {
    Header: "Visited",
    accessor: "po_visited_houses",
  },
  {
    Header: "Total %",
    accessor: "po_visited_houses_prc",
  },
];

export const bsbgOutreach = [
  {
    Header: "Total",
    accessor: "bsbg_total_houses",
  },
  {
    Header: "Enrolled",
    accessor: "bsbg_total_houses_enrolled",
  },
  {
    Header: "Total %",
    accessor: "bsbg_total_houses_completed_prc",
  },
];

export const newVoterRegistration = [
  {
    Header: "New Voters",
    accessor: "new_voters",
  },
  {
    Header: "Enrolled",
    accessor: "new_voters_registrations",
  },
  {
    Header: "Total %",
    accessor: "new_voters_registrations_prc",
  },
];

export const electionResults = [
  {
    Header: "Winning Party",
    accessor: "winning_party",
  },
  {
    Header: "NDA Votes",
    accessor: "nda_vote_count",
  },
  {
    Header: "NDA Vote %",
    accessor: "nda_vote_prc",
  },
  {
    Header: "YSRC Votes",
    accessor: "ysrc_vote_count",
  },
  {
    Header: "YSRC Vote %",
    accessor: "ysrc_vote_prc",
  },
  {
    Header: "MAJORITY",
    accessor: "majority",
  },
  {
    Header: "Won",
    accessor: "won",
  }
];

export const zonalCoordinatorFeedbackHeader = [
  {
    Header: "Question",
    accessor: "question",
  },
  {
    Header: "Response",
    accessor: "response",
  },
  {
    Header: "Comments",
    accessor: "comments",
  }
];

export const zonalObserverFeedbackHeader = [
  {
    Header: "Name of Observer",
    accessor: "observer_name",
  },
  {
    Header: "Was the Observer Effective",
    accessor: "observer_effective",
  },
  {
    Header: "Comments",
    accessor: "comments",
  }
];