// Chakra imports
import {Box, Icon, Text, Flex, useColorModeValue} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React from "react";
import {RiArrowUpSFill, RiArrowDownFill} from "react-icons/ri";

export default function Information(props) {
  const { title, value, prc, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const bg = useColorModeValue("white", "navy.700");
  let color = textColorPrimary;
  if(title === 'Overall Grade' || title==='Grade') {
    if (value === 'A') {
      color = 'green.500'
    } else if (value === 'B'){
      color = 'green.300'
    } else if (value === 'C'){
      color = 'yellow.300'
    } else if (value === 'E'){
      color = 'red.500'
    } else {
      color = 'red.200'
    }
  }
  if(title === 'Overall Grade %') {
    if (value >= 85 ) {
      color = 'green.500'
    } else if (value >= 75 && value < 85){
      color = 'green.300'
    } else if (value >= 55 && value < 75){
      color = 'yellow.300'
    } else if (value >= 40 && value < 55){
      color = 'red.200'
    } else {
      color = 'red.500'
    }
  }
  if(title === 'Gain') {
    if (value > 0 ) {
      color = 'green.500'
    }  else if (value === 0){
      color = 'orange.500'
    } else {
      color = 'red.500'
    }
  }
  return (
      <Card bg={bg} {...rest}>
        <Box>
          { title === 'Gain'?
              <div>
                { prc > 0 ?
                    <Text fontWeight='700' color={textColorSecondary} fontSize='md'>
                      {title} <Icon as={RiArrowUpSFill} color={color} /> <Text align={'right'} color={color} fontSize='sm' fontWeight='700'>  {prc} % </Text>
                    </Text>
                    :
                    <Text fontWeight='700' color={textColorSecondary} fontSize='md'>
                      {title} <Icon as={RiArrowDownFill} color={color} /> <Text align={'right'} color={color} fontSize='sm' fontWeight='700'>  {prc} % </Text>
                    </Text>
                }

              </div>
              :
              <Text fontWeight='700' color={textColorSecondary} fontSize='md'>
                {title}
              </Text>
          }
          { title === 'Overall Grade' || title === 'Overall Grade %'  ?
              (<Text color={color} align={"center"} fontWeight='700' fontSize='7xl'>
                {value}
              </Text>) :
              (title === 'Grade' || title === 'Gain')?
                  (<Text color={color} align={"center"} fontWeight='700' fontSize='4xl'>
                    {value}
                  </Text>)
                  :
                  (<Text color={textColorPrimary} fontWeight='700' fontSize='md'>
                    {value}
                  </Text>)
          }
        </Box>
      </Card>
  );
}
