// Chakra imports
import {Box, Grid, Text, useColorModeValue} from "@chakra-ui/react";
// Assets
import {React, useEffect, useState} from "react";
import ComplexTable from "../default/components/ComplexTable";
import {
  boothElectionResultsData,
  leaderFeedbackData, leaderFeedbackVillageData,
  mandalElectionResultsData,
  topPerformingBoothsResultsData,
  topPerformingVillagesResultsData,
  villageElectionResultsData
} from "../default/variables/columnsData";
import PieCard from "../default/components/PieCard";
import {getBoothDetailsById, getParameterByName, getVillageDetailsById} from "../../../api/api-helpers";
import {Banner} from "../profile/components/Storage"
import General from "../profile/components/General";

export default function Overview() {
  let acNo = getParameterByName('ac_no');
  let mandalName = getParameterByName('mandal_name');
  let villageName = getParameterByName('village_name');
  let boothId = getParameterByName('booth_id');
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [booth, setBooth] = useState();
  const [constituency, setConstituency] = useState();
  const [electionResults, setElectionResults] = useState();
  const [electionDefCharts, setElectionDefCharts] = useState();
  const [feedback, setFeedback] = useState();


  useEffect(() => {
    const fetchCandidateDetails = async () => {
      try {
        const response = await getBoothDetailsById(acNo, mandalName, villageName, boothId);
        let boothData = response.data
        let acResults  = boothData['election_results'];
        if (acResults && acResults.length > 0){
          let electionCharts = [];
          acResults = acResults[0];
          electionCharts.push(acResults['tdp_votes']);
          electionCharts.push(acResults['ysrc_votes']);
          electionCharts.push(acResults['votes_polled'] - acResults['tdp_votes'] - acResults['ysrc_votes']);
          setElectionDefCharts(electionCharts);
        }
        setElectionResults(boothData['election_results']);
        setConstituency(boothData['booth_id']);
        setFeedback(boothData['feedback']);
        setBooth(boothData);
        setFeedback(boothData['feedback_details'])
      } catch (error) {
        console.error('Error fetching candidates:', error);
      }
    };
    fetchCandidateDetails();
  }, []);
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");

  return (
      <div id="candidate-profile">
        {booth ?
            <Box pt={{base: "50px", md: "50px", xl: "50px"}}>
              <Grid
                  templateColumns={{
                    base: "0fr",
                    lg: "1.34fr 1fr 1.62fr",
                  }}
                  templateRows={{
                    base: "repeat(3, 1fr)",
                    lg: "1fr",
                  }}
                  gap={{base: "20px", xl: "20px"}}>
                <Banner  gridArea='1 / 1 / 2 / 2'
                         name={booth['booth_id']}
                         villageName={booth['village_name']}
                         mandalName={mandalName}
                         assembly={booth['assembly']}
                         parliament={booth['parliament']}
                         grade={booth['grading']}
                         gain={booth['tdp_gain']}
                         gain_prc={booth['tdp_gain_prc']}
                />
                {electionDefCharts ?
                    <PieCard gridArea={{base: "2 / 1 / 2 / 2", lg: "1 / 2 / 2 / 5"}}
                             name={`Election Results -  ${constituency}`} type={"donut"} results={electionDefCharts}
                             labels={['TDP', 'YSRC', 'Others']}/> :
                    null
                }
              </Grid>
              <br/>
              {electionResults ?
                  <ComplexTable
                      name={`Election Results - ${constituency}`}
                      acNo={acNo}
                      columnsData={boothElectionResultsData}
                      tableData={electionResults}
                  /> : null}
              <br/>
              <div>
                <br/>
                <hr/>
                <Text
                    align={"center"}
                    color={textColorPrimary}
                    fontWeight='bold'
                    fontSize='2xl'
                    mt='10px'
                    mb='4px'>
                  CUBS Feedback
                </Text>
                <hr/>
                <br/>
              </div>
            {feedback ?
                  <ComplexTable
                      name={"CUBS Feedback"}
                      columnsData={leaderFeedbackVillageData}
                      tableData={feedback}
                  /> : null}
          </Box> : <div align={"center"}>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <iframe src="https://lottie.host/embed/c698f656-918b-4a16-8678-f1b7bc4a79f3/n1SzSdcaUG.json"></iframe>
        <br/>

      </div>}
</div>
);
}
