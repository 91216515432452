// Chakra imports
import {
    Box,
    Flex,
    Icon,
    Progress,
    SimpleGrid,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import IconBox from "components/icons/IconBox";
import Menu from "components/menu/MainMenu";
import React from "react";
// Assets
import { MdOutlineCloudDone } from "react-icons/md";
import Information from "./Information";

export function Banner(props) {
    const { name, villageName, mandalName, assembly, parliament, grade, gain, gain_prc, district } = props;
    // Chakra Color Mode
    const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
    const brandColor = useColorModeValue("brand.500", "white");
    const textColorSecondary = "gray.400";
    const box = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
    const cardShadow = useColorModeValue(
        "0px 18px 40px rgba(112, 144, 176, 0.12)",
        "unset"
    );
    return (
        <Card align='center' direction='column' w='100%' mb={{ base: "0px", lg: "20px" }} style={{ borderWidth: '1px', marginBottom: '0px'}}>
            <Text color={textColorPrimary} fontWeight='bold' fontSize='xl' mt='10px'>
                {name}
            </Text>
            { villageName ?
                <Text color={textColorPrimary} fontSize='md' fontWeight={"700"}>
                    Village: {villageName}
                </Text>: null}
            { mandalName ?
                <Text color={textColorPrimary} fontSize='md' fontWeight={"700"}>
                    Mandal: {mandalName}
                </Text>: null}
            { assembly ?
                <Text color={textColorPrimary} fontSize='md' fontWeight={"700"}>
                    Assembly: {assembly}
                </Text> : null
            }
            <Text color={textColorPrimary} fontSize='md' fontWeight={"700"}>
                Parliament: {parliament}
            </Text>
            { district ?
                <Text color={textColorPrimary} fontSize='md' fontWeight={"700"}>
                    District: {district}
                </Text>: null}
            <br/>
            <br/>
            <SimpleGrid columns='2' gap='10px'>
                <Information
                    boxShadow={cardShadow}
                    title='Grade'
                    value={grade}
                    style={{borderWidth:'1px'}}
                />
                <Information
                    boxShadow={cardShadow}
                    title='Gain'
                    value={gain}
                    prc={gain_prc}
                    style={{borderWidth:'1px'}}
                />
            </SimpleGrid>
        </Card>
    );
}
