// Chakra imports
import { SimpleGrid, Text, useColorModeValue, Box } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React from "react";
import Information from "views/admin/profile/components/Information";
import PieCard from "../../default/components/PieCard";

// Assets
export default function GeneralInformation(props) {
    const { education, batch, zone, ph_no, partyPosition,...rest } = props;
    console.log(education)
    // Chakra Color Mode
    const textColorPrimary = useColorModeValue("secondaryGray.900", "white")
    const bg = useColorModeValue("white", "navy.700");
    const textColorSecondary = "gray.400";
    const cardShadow = useColorModeValue(
        "0px 18px 40px rgba(112, 144, 176, 0.12)",
        "unset"
    );
    return (
        <Card mb={{ base: "0px", "2xl": "20px" }} align={"left"} style={{marginBottom:"15px",borderWidth:'1px'}} {...rest}>
            <Text
                color={textColorPrimary}
                fontWeight='bold'
                fontSize='2xl'
                mt='10px'
                mb='4px'>
                Grading
            </Text>
            <br/>
            <SimpleGrid columns='3' gap='10px'>
                <Information
                    boxShadow={cardShadow}
                    title='Overall Grade'
                    value={education}
                    style={{borderWidth:'1px'}}
                />
                <Information
                    boxShadow={cardShadow}
                    title='Overall Grade %'
                    value={zone}
                    style={{borderWidth:'1px'}}
                />
                <Card bg={bg} style={{borderWidth:'1px'}}>
                    <Box>
                        <Text fontWeight='700' color={textColorSecondary} fontSize='md'>
                            Availability
                        </Text>
                        <br/>
                        <PieCard type={"guage"} percentage={ph_no}/>
                    </Box>
                </Card>

                {/*<Information
          boxShadow={cardShadow}
          title='Zone'
          value={zone}
        />
        <Information
          boxShadow={cardShadow}
          title='Batch'
          value={batch}
        />*/}
            </SimpleGrid>
        </Card>
    );
}
