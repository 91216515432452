import {React, useEffect, useState} from 'react';
import {
    IconButton,
    Input,
    InputGroup,
    InputLeftElement,
    useColorModeValue,
} from "@chakra-ui/react";
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import {getAssembly, getMandals, getVillages, getBooths, getParameterByName} from "../../api/api-helpers";

export function ConstituencyMenu(props) {
    const { variant, background, children, placeholder, borderRadius, searchItems, ...rest } =
        props;
    // Chakra Color Mode
    const ac_no = getParameterByName("ac_no");
    const mandal_name = getParameterByName("mandal_name");
    const village_name = getParameterByName("village_name");
    const booth_id = getParameterByName("booth_id");
    const inputBg = useColorModeValue("secondaryGray.300", "navy.900");
    const inputText = useColorModeValue("gray.700", "gray.100");
    const [items, setItems] = useState();
    useEffect(() => {
        const fetchCandidates = async () => {
            try {
                let response = null;
                if(!mandal_name) {
                    response = await getAssembly();
                    const results = response.data.results;
                    const items = results.map(cand => ({ id: cand['ac_no'], name: cand['ac_name']}));
                    setItems(items);
                } else if(mandal_name && !village_name){
                    response = await getMandals(ac_no);
                    const results = response.data.results;
                    const items = results.map(cand => ({ id: cand, name: cand}));
                    setItems(items);
                } else if (mandal_name && village_name && !booth_id){
                    response = await getVillages(ac_no, mandal_name);
                    const results = response.data.results;
                    const items = results.map(cand => ({ id: cand, name: cand}));
                    setItems(items);
                } else if (mandal_name && village_name && booth_id){
                    response = await getBooths(ac_no, mandal_name, village_name);
                    const results = response.data.results;
                    const items = results.map(cand => ({ id: cand, name: cand}));
                    setItems(items);
                }

            } catch (error) {
                console.error('Error fetching candidates:', error);
            }
        };
        fetchCandidates();
    }, []); // Empty dependency arra

    const handleOnSearch = (string, results) => {
        // Triggered when the user types in the search input
        console.log(string, results);
    };

    const handleOnHover = (item) => {
        // Triggered when the user hovers over an item in the suggestions list
        console.log('Item hovered:', item);
    };

    const handleOnChange = (item) => {
        let url = ''
        if(!mandal_name) {
            url = `#/admin/default?ac_no=${item.id}`;
        } else if(mandal_name && !village_name){
            url = `#/admin/mandal-results?ac_no=${ac_no}&mandal_name=${item.name}`;
        } else if (mandal_name && village_name && !booth_id){
            url = `#/admin/village-results?ac_no=${ac_no}&mandal_name=${mandal_name}&village_name=${item.name}`;
        } else if (mandal_name && village_name && booth_id){
            url = `#/admin/booth-results?ac_no=${ac_no}&mandal_name=${mandal_name}&village_name=${village_name}&booth_id=${item.name}`;
        }
        window.location.href = url;
        window.location.reload();
    }


    return (
        <InputGroup w={{base: "100%", md: "200px"}} {...rest}>
            <InputLeftElement
                children={
                    <IconButton
                        bg='inherit'
                        borderRadius='inherit'
                        _hover='none'
                        _active={{
                            bg: "inherit",
                            transform: "none",
                            borderColor: "transparent",
                        }}
                        _focus={{
                            boxShadow: "none",
                        }}
                    ></IconButton>
                }
            />
            <div style={{width: '100%'}}>
                <ReactSearchAutocomplete
                    fontSize='sm'
                    bg={background ? background : inputBg}
                    color={inputText}
                    fontWeight='500'
                    _placeholder={{ color: "gray.400", fontSize: "14px" }}
                    borderRadius={borderRadius ? borderRadius : "30px"}
                    placeholder={placeholder ? placeholder : "Search..."}
                    items={items}
                    onSearch={handleOnSearch}
                    onHover={handleOnHover}
                    onSelect={handleOnChange}
                />
            </div>

        </InputGroup>
    );
}
